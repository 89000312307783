import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import FieldInput from "../global/form/FieldInput";
import { Formik } from "formik";

//Custom styles
const customStyles = {
  elementsItem: {
    border: "solid 1px #cccccc",
    borderRadius: "8px",
  },
  pushButtonIcons: {
    cursor: "n-resize",
  },
  noPaddingIcons: {
    padding: "0px",
  },
  removeElement: {
    fontSize: "13px",
    padding: "4px", //remove button
    position: "relative",
    top: "-15px",
    cursor: "pointer",
  },
  removeElementAverage: {
    fontSize: "15px",
    marginTop: "-43px",
    cursor: "pointer",
    float: "right",
  },
  subjectRemoveIcon: {
    fontSize: "15px",
    marginTop: "-50px",
    cursor: "pointer",
    float: "right",
  },
  blankLineRemoveIcon: {
    fontSize: "15px",
    marginTop: "-38px",
    cursor: "pointer",
    float: "right",
  },
};

/**
 * Converts base items to interactive elements for template building
 * @Props {Obj} item  - Element of some kind to be added to the template
 * @Props {Int} index - Position of this item in the list
 * @props {func} getOptions - optienes los opciones del AsyncSelect
 * @props {array} defaulsOptions - contienes las opciones por defecto para el AsyncSelect
 * @props {func} selectClass - almacena el valor de la opcion seleccionada
 * @returns Block element.
 */
export default function ReportCardItemCreator(props) {
  const [t] = useTranslation(["reportCards"]);
  let designItem = false;
  let item = props.item;
  let index = props.position;
  const commentView = props.commentView;
  switch (item.type) {
    case "phantomElement":
      // elemente that keeps the sortable Js component even if all items have been removed.
      designItem = <Col md={12} key={index} id={index}></Col>;
      break;
    case "subject":
      designItem = (
        <Col
          md={12}
          style={customStyles.elementsItem}
          key={index}
          id={index}
          className="mb-3 p-1"
        >
          <Row>
            {item.selectionMode ? (
              <Col md={10}>
                <Form.Check
                  name="averaging"
                  type="checkbox"
                  checked={item.averaging}
                  onChange={() => {
                    props.selectClassesForAveraging(
                      item && item.value && item.value.value
                        ? item.value.value
                        : null,
                      index,
                      !item.averaging
                    );
                  }}
                  label={item && item.value ? item.value.label : ""}
                />
              </Col>
            ) : (
              <Col md={10}>
                <AsyncSelect
                  loadOptions={props.getOptions}
                  defaultOptions={props.defaulsOptions}
                  onChange={(item) => props.selectClass(item, index)}
                  value={item.value ? item.value : null}
                  isDisabled={item.disabled}
                  formatOptionLabel={function (data) {
                    return (
                      <span dangerouslySetInnerHTML={{ __html: data.label }} />
                    );
                  }}
                />
              </Col>
            )}
            <Col md={1}>
              <i
                style={customStyles.pushButtonIcons}
                className="bi bi-list float-end mt-1"
              ></i>
            </Col>
          </Row>
          {!item.disabled && (
            <i
              style={customStyles.subjectRemoveIcon}
              className="bi bi-x-circle-fill text-danger"
              onClick={() => props.removeItemByButton(item, index)}
            ></i>
          )}
        </Col>
      );
      break;
    case "blankLine":
      designItem = (
        <Col
          md={12}
          style={customStyles.elementsItem}
          id={index}
          key={index}
          className={commentView ? "hide" : "mb-3 p-1"}
        >
          <Row>
            <Col md={10} className="opacity-25">
              {t("reportCards:draggableElements.blankLine")}
            </Col>
            <Col md={1}>
              <i
                style={customStyles.pushButtonIcons}
                className="bi bi-list float-end"
              ></i>
            </Col>
          </Row>
          {!item.disabled && (
            <i
              style={customStyles.blankLineRemoveIcon}
              className="bi bi-x-circle-fill text-danger"
              onClick={() => props.removeItemByButton(item, index)}
            ></i>
          )}
        </Col>
      );
      break;
    case "dottedLine":
      designItem = (
        <Col
          md={12}
          style={customStyles.elementsItem}
          key={index}
          id={index}
          className={commentView ? "hide" : "mb-3 p-1"}
        >
          <Row>
            <Col md={10}>- - - - - - - - - - - </Col>
            <Col md={1}>
              <i
                style={customStyles.pushButtonIcons}
                className="bi bi-list float-end"
              ></i>
            </Col>
          </Row>
          {!item.disabled && (
            <i
              style={customStyles.blankLineRemoveIcon}
              className="bi bi-x-circle-fill text-danger"
              onClick={() => props.removeItemByButton(item, index)}
            ></i>
          )}
        </Col>
      );
      break;
    case "average":
      designItem = (
        <Col
          md={12}
          style={customStyles.elementsItem}
          key={index}
          id={index}
          className={commentView ? "hide" : "mb-3 p-1"}
        >
          <Row>
            <Col md={9}>
              <Formik initialValues={item.values}>
                {(values) => (
                  <Row>
                    <Col lg={12} style={{ maxHeight: "30px" }}>
                      <FieldInput
                        name="name"
                        type="text"
                        label={""}
                        placeholder=""
                        onBlur={() => {
                          item.values.name = values.values.name;
                        }}
                        value={values.values.name}
                        style={{
                          padding: "0px 10px 0px 10px",
                        }}
                        className={""} //set to false to remove the bottom margin
                        disabled={item.disabled}
                      />
                    </Col>
                  </Row>
                )}
              </Formik>
            </Col>

            {item.selectionMode ? (
              <Col md={1} style={customStyles.noPaddingIcons}>
                <i
                  style={{ cursor: "pointer" }}
                  className="bi bi-check-circle-fill float-end me-1 text-danger"
                  onClick={() => props.changeClassesSelectionMode(false, index)}
                ></i>
              </Col>
            ) : (
              <Col md={1} style={customStyles.noPaddingIcons}>
                <i
                  style={
                    item.disabled ? { display: "none" } : { cursor: "pointer" }
                  }
                  className="bi bi-gear-fill float-end me-1 text-danger"
                  onClick={() => props.editAveragingFormat(item.values, index)}
                  disabled={item.disabled}
                ></i>
              </Col>
            )}

            <Col md={1} style={customStyles.noPaddingIcons}>
              <i
                style={customStyles.pushButtonIcons}
                className="bi bi-list"
              ></i>
            </Col>
          </Row>
          {!item.disabled && (
            <i
              style={customStyles.removeElementAverage}
              className="bi bi-x-circle-fill text-danger"
              onClick={() => props.removeItemByButton(item, index)}
            ></i>
          )}
        </Col>
      );
      break;
    case "absences":
      designItem = (
        <Col
          md={12}
          style={customStyles.elementsItem}
          key={index}
          id={index}
          className={commentView ? "hide" : "mb-3 p-1"}
        >
          <Row>
            <Col md={10}>{t("reportCards:draggableElements.assistance")}</Col>
            <Col md={1}>
              <i
                style={customStyles.pushButtonIcons}
                className="bi bi-list float-end"
              ></i>
            </Col>
          </Row>
          {!item.disabled && (
            <i
              style={customStyles.blankLineRemoveIcon}
              className="bi bi-x-circle-fill text-danger"
              onClick={() => props.removeItemByButton(item, index)}
            ></i>
          )}
        </Col>
      );
      break;
    case "groupAverage":
      designItem = (
        <Col
          md={12}
          style={customStyles.elementsItem}
          key={index}
          id={index}
          className={commentView ? "hide" : "mb-3 p-1"}
        >
          <Row>
            <Col md={10}>{t("reportCards:draggableElements.groupAverage")}</Col>
            <Col md={1}>
              <i
                style={customStyles.pushButtonIcons}
                className="bi bi-list float-end"
              ></i>
            </Col>
          </Row>
          {!item.disabled && (
            <i
              style={customStyles.blankLineRemoveIcon}
              className="bi bi-x-circle-fill text-danger"
              onClick={() => props.removeItemByButton(item, index)}
            ></i>
          )}
        </Col>
      );
      break;
    default:
      designItem = (
        <Col
          md={12}
          style={customStyles.elementsItem}
          key={index}
          id={index}
          className="mb-3 p-1"
        >
          <i
            style={customStyles.pushButtonIcons}
            className="pushButtonIcons bi bi-list float-end"
          ></i>
          {t("reportCards:draggableElements.default")}
        </Col>
      );
  }

  return designItem;
}

ReportCardItemCreator.propTypes = {
  getOptions: PropTypes.func,
  editAveragingFormat: PropTypes.func,
};
