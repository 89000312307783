/**
 * PaymentsView Component
 *
 * Purpose: Contains the Payment Activity modules
 *
 * @author Marisol Torres <marisol@inclancommunications.com>
 * @date 22/02/2022 12:51:25 PM
 */

import React, { useContext, useEffect, useState } from "react";
import { Container, Nav, Tab, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { goToHref, userInfo } from "../../components/global/GlobalTools";
import { GetHideMenus } from "../../components/global/GlobalTools";
import PropTypes from "prop-types";
import { NavTabsContext } from "../../components/DashboardLayout";
import NavDropdown from "react-bootstrap/NavDropdown";

/** Services */

/** Elements */
import { MainContext } from "../../App";

/** Views */
import TransactionsReportView from "./TransactionsReportView";
import DebtReportView from "./DebtReportView";
import IncomeView from "./IncomeView";
import ScholarshipStudent from "./ScholarshipStudents ";
import { useHistory } from "react-router";
import TypeScholarships from "./TypeScholarships";

const PaymentsView = (props) => {
  const { selectedTab } = useContext(NavTabsContext);
  const { permissions } = useContext(MainContext);
  const prefix = process.env.REACT_APP_PREFIX;
  const [t] = useTranslation(["reports", "global", "scholarships"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const [language] = useState(localStorage.getItem(`cmLanguage${prefix}`));
  const hideMenus = parseInt(GetHideMenus());
  const showNavItem = hideMenus !== 1; // to know when to show/hide the nav when it comes from the mobile app
  let previusTab =
    props.match.params && props.match.params.view
      ? props.match.params.view
      : "transactions";
  const [refreshTabContent, setRefreshTabContent] = useState(false);
  const [focusedTab, setFocusedTab] = useState(previusTab);
  const history = useHistory();
  /**
   * Purpose: Redirect Cash register, Account Status, Transactions/Invoices, Subconcepts and cards to App1
   * @param {String} tabView
   */
  const goApp1 = (tabView) => {
    var now = new Date();
    now.setDate(now.getDate() + 5);
    goToHref(`/payments/${tabView}`);
  };

  /**Initial loading */
  useEffect(() => {
    const availableTabs = [
      "transactions",
      "debt",
      "income",
      "scholarships",
      "typeScholarships",
    ];
    if (!availableTabs.includes(focusedTab)) {
      history.push("/payments");
    }
    document.title =
      language == "spanish" ? "Pagos - Adeudos" : "Payments - Debts";
  }, []);

  useEffect(() => {
    setRefreshTabContent(false);
  }, [selectedTab]);

  //Obtain basic user information (role, id and token)
  const basicUserData = userInfo();

  return (
    <Container fluid>
      <Col md={12}>
        <h2> {t("scholarships:tabBar." + focusedTab)}</h2>
      </Col>
      {!refreshTabContent && (
        <Tab.Container id="payment" defaultActiveKey={focusedTab}>
          <Nav
            variant="pills"
            activeKey={focusedTab}
            style={{ width: "fit-content", margin: "0 auto" }}
          >
            {/** This code will be used for transactions */}
            {permissions.payments_cashier.access && (
              <Nav.Item>
                <a
                  href={`${process.env.REACT_APP_V1}/payments/cashRegister`}
                  style={{ textDecoration: "none" }}
                >
                  <Nav.Link
                    eventKey="cashRegister"
                    onClick={() => goApp1("cashRegister")}
                  >
                    {basicUserData.isParent || basicUserData.isStudent
                      ? t("paymentsTabs.makePayment")
                      : t("paymentsTabs.cashRegister")}
                  </Nav.Link>
                </a>
              </Nav.Item>
            )}
            {/**Account status tab button*/}
            {showNavItem && permissions.payments_account_status.access && (
              <Nav.Item>
                <a
                  href={`${process.env.REACT_APP_V1}/payments/accountStatus`}
                  style={{ textDecoration: "none" }}
                >
                  <Nav.Link
                    eventKey="statementAccount"
                    onClick={() => goApp1("accountStatus")}
                  >
                    {t("paymentsTabs.statementAccount")}
                  </Nav.Link>
                </a>
              </Nav.Item>
            )}
            {/** Transactions/Invoices tab button */}
            {showNavItem && permissions.payments_transactions.access && (
              <Nav.Item>
                <a
                  href="/payments/transactions"
                  style={{ textDecoration: "none" }}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Nav.Link
                    eventKey="transactions"
                    onClick={() => {
                      setFocusedTab("transactions");
                      history.push("/payments/transactions");
                    }}
                  >
                    {t("paymentsTabs.transactionsInvoices")}
                  </Nav.Link>
                </a>
              </Nav.Item>
            )}

            {showNavItem && permissions.payments_debts.access && (
              <Nav.Item>
                <a
                  href="/payments/debt"
                  style={{ textDecoration: "none" }}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Nav.Link
                    eventKey="debt"
                    onClick={() => {
                      setFocusedTab("debt");
                      history.push("/payments/debt");
                    }}
                  >
                    {t("reports.debt")}
                  </Nav.Link>
                </a>
              </Nav.Item>
            )}
            {showNavItem && permissions.payments_incomes.access && (
              <Nav.Item>
                <a
                  href="/payments/income"
                  style={{ textDecoration: "none" }}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Nav.Link
                    eventKey="income"
                    onClick={() => {
                      setFocusedTab("income");
                      history.push("/payments/income");
                    }}
                  >
                    {t("paymentsTabs.income")}
                  </Nav.Link>
                </a>
              </Nav.Item>
            )}
            {showNavItem && permissions.payments_subconcepts.access && (
              <Nav.Item>
                <a
                  href={`${process.env.REACT_APP_V1}/payments/subconcepts`}
                  style={{ textDecoration: "none" }}
                >
                  <Nav.Link
                    eventKey="subconcepts"
                    onClick={() => goApp1("subconcepts")}
                  >
                    {t("paymentsTabs.subconcepts")}
                  </Nav.Link>
                </a>
              </Nav.Item>
            )}
            {showNavItem &&
              (permissions.payments_students_scholarships.access ||
                permissions.payments_scholarships.access) && (
                <NavDropdown
                  title={t("scholarships:textTitle.titleScholarships")}
                  id="nav-dropdown"
                  className={
                    focusedTab == "scholarships" ||
                    focusedTab == "typeScholarships"
                      ? "nav-dropdown-cm"
                      : ""
                  }
                >
                  <a
                    href="/payments/scholarships"
                    style={{ textDecoration: "none" }}
                  >
                    <NavDropdown.Item
                      eventKey="scholarships"
                      onClick={() => {
                        setFocusedTab("scholarships");
                        history.push("/payments/scholarships");
                      }}
                    >
                      {t("scholarships:textTitle.titleScholarshipRecipients")}
                    </NavDropdown.Item>
                  </a>
                  <a
                    href="/payments/typeScholarships"
                    style={{ textDecoration: "none" }}
                  >
                    <NavDropdown.Item
                      eventKey="typeScholarships"
                      onClick={() => {
                        setFocusedTab("typeScholarships");
                        history.push("/payments/typeScholarships");
                      }}
                    >
                      {t("scholarships:tabBar.typeScholarships")}
                    </NavDropdown.Item>
                  </a>
                </NavDropdown>
              )}
            {showNavItem && permissions.payments_cards.access && (
              <Nav.Item>
                <a
                  href={`${process.env.REACT_APP_V1}/payments/cards`}
                  style={{ textDecoration: "none" }}
                >
                  <Nav.Link eventKey="myCards" onClick={() => goApp1("cards")}>
                    {t("paymentsTabs.myCards")}
                  </Nav.Link>
                </a>
              </Nav.Item>
            )}
          </Nav>

          <div style={{ marginTop: "20px" }}>
            <Tab.Content>
              {focusedTab == "transactions" && (
                <Tab.Pane eventKey="transactions">
                  {/** Report Transactions **/}
                  <TransactionsReportView defaultTabView={focusedTab} />
                </Tab.Pane>
              )}
              {focusedTab == "debt" && (
                <Tab.Pane eventKey="debt">
                  {/** Report Debts **/}
                  <DebtReportView />
                </Tab.Pane>
              )}
              {focusedTab == "income" && (
                <Tab.Pane eventKey="income">
                  <IncomeView />
                </Tab.Pane>
              )}
              {focusedTab == "scholarships" && (
                <Tab.Pane eventKey="scholarships">
                  <ScholarshipStudent />
                </Tab.Pane>
              )}
              {focusedTab == "typeScholarships" && (
                <Tab.Pane eventKey="typeScholarships">
                  <TypeScholarships />
                </Tab.Pane>
              )}
            </Tab.Content>
          </div>
        </Tab.Container>
      )}
    </Container>
  );
};

export default PaymentsView;

PaymentsView.propTypes = {
  match: PropTypes.object,
};
