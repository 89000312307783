import React, { useEffect, useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col, Dropdown, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { MainContext } from "../../App";
import * as Sentry from "@sentry/react";

/** Services */
import {
  GetPayments,
  GetPdfReceipts,
  printMultipleInvoice,
  DownloadMultiple,
  DownloadMultipleReceipts,
} from "../../api/PaymentsReport";

/** Elements */
import { Table } from "../../components/table/Table";
import ContentTable from "../../components/table/ContentTable";
import ContentSolid from "../../components/global/ContentSolid";
import SelectFilter from "../../components/table/SelectFilter";
import DateRangeFilter from "../../components/table/DateRangeFilter";
import Label from "../../components/table/Label";
import { LoadingTable } from "../../components/lazyLoading/LazyLoading";
import TextLoading from "../../components/global/TextLoading";
import {
  getUrlParamValue,
  goToHref,
} from "../../components/global/GlobalTools";
import InputFilter from "../../components/table/InputFilter";
import ExportToExcel from "../../components/table/ExportToExcel";
import * as moment from "moment";
import MassBillingModal from "./modals/MassBillingModal";
import ModalPaymentsNonEligibleForBilling from "./modals/ModalPaymentsNonEligibleForBilling";
import ModalUnbilledPayments from "./modals/ModalUnbilledPayments";
import MailingModal from "./modals/MailingModal";
import ModalInvoicePrinting from "./modals/ModalInvoicePrinting";
import ModalReportSAP from "../../components/payments/modals/ModalReportSAP";
//Custom styles section
const customStyles = {
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
};

/**Get param to open massive billing modal */
const massBilling = getUrlParamValue("massBilling");
const TransactionsReportView = (props) => {
  const prefix = process.env.REACT_APP_PREFIX;
  const [t] = useTranslation(["reports", "global", "address"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const { permissions } = useContext(MainContext);
  const [language] = useState(localStorage.getItem(`cmLanguage${prefix}`));
  const componentRef = useRef(); //Create the reference for Printing
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingView, setLoadingView] = useState(true);
  const [exportData, setExportData] = useState([]);
  const [printExport, setPrintExport] = useState(true);
  const [showEditColumns, setShowEditColumns] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [allPaymentIds, setAllPaymentIds] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [filters, setFilters] = useState([]);
  const status = [
    {
      value: "all",
      valueLabelEn: "All",
      valueLabelEs: "Todos",
      classStyle: "",
    },
    {
      value: "completed",
      valueLabelEn: "Paid out",
      valueLabelEs: "Pagado",
      classStyle: "success",
    },
    {
      value: "in_progress",
      valueLabelEn: "Pending",
      valueLabelEs: "Pendiente",
      classStyle: "warning",
    },
    {
      value: "refunded",
      valueLabelEn: "Refund",
      valueLabelEs: "Reembolso",
      classStyle: "info",
    },
    {
      value: "cancelled",
      valueLabelEn: "Cancelled",
      valueLabelEs: "Cancelado",
      classStyle: "danger",
    },
    {
      value: "charge_pending",
      valueLabelEn: "Charge Pending",
      valueLabelEs: "Cargo Pendiente",
      classStyle: "warning",
    },
    {
      value: "expired",
      valueLabelEn: "Expired",
      valueLabelEs: "Expirado",
      classStyle: "secondary",
    },
    {
      value: "failed",
      valueLabelEn: "Failed",
      valueLabelEs: "Fallido",
      classStyle: "danger",
    },
  ];
  const [error, setError] = useState("");
  const [statusOptions, setStatusOptions] = useState([]);
  const actionOptions = [
    { value: "receipts", label: "actionOptions.receipts" },
    { value: "invoices", label: "actionOptions.invoices" },
  ];
  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  const [showMassBillingModal, setShowMassBillingModal] = useState(massBilling);
  const [disabledActions, setDisabledActions] = useState(false);
  const [
    showModalPaymentsNonEligibleForBilling,
    setShowModalPaymentsNonEligibleForBilling,
  ] = useState(false);
  const [nonBillablePayments, setNonBillablePayments] = useState([]);
  const [showModalUnbilledPayments, setShowModalUnbilledPayments] =
    useState(false);
  const [billablePayments, setBiillablePayments] = useState([]);
  const [idsPrint, setIdsPrint] = useState([]);
  const [confirmations, setConfirmations] = useState([]);
  const [actionType, setActionType] = useState("");
  const [requestFilter, setRequestFilter] = useState({});
  const [showMailingModal, setShowMailingModal] = useState(false);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [messageMailingModal, setMessageMailingModal] = useState({});
  const [textMessageMailingModal, setTextMessageMailingModal] = useState({});
  const [showModalInvoicePrinting, setShowModalInvoicePrinting] =
    useState(false);
  const [widthSize, setWidthSize] = useState(window.innerWidth);
  const [mobileSize] = useState(810);
  const [btnMarginR, setBtnMarginR] = useState({
    marginRight: window.innerWidth <= mobileSize ? "0px" : "10px",
  });
  const [count, setCount] = useState(0);
  const services = JSON.parse(localStorage.getItem(`${prefix}Services`));
  const [sapReportSAP, setSapReportSAP] = useState({ showModalReportSAP: false, type: "" });

  //Options to export to excel
  const exportOptions = {
    openAsDownload: true,
    format: "xlsx", //'xlsx' or 'xls' or 'csv'
    filename: t("report"),
  };

  //Allow billing options
  const allowBillingOptions = [
    {
      value: "Yes",
      label: t("global:yes"),
      id: t("global:yes"),
    },
    {
      value: "No",
      label: t("global:no"),
      id: t("global:no"),
    },
  ];
  /*
   * Purpose: Print invoices in pdf
   */
  const printInvoices = (paymentConfirmations, omit = false) => {
    setIsLoadingInvoices(true);
    Array.isArray(paymentConfirmations) ? null : (paymentConfirmations = []);
    if (!paymentConfirmations.length) {
      if (selectedRowIds.length && selectedRowIds[0].original) {
        for (let bill of selectedRowIds) {
          paymentConfirmations.push(bill.original.confirmation);
        }
      }
    }
    setConfirmations(paymentConfirmations);
    setActionType("print");
    let confirmations = JSON.stringify(paymentConfirmations);
    let data = { confirmations: confirmations };
    if (omit) {
      data.ignore_not_billed = true;
    }
    printMultipleInvoice(data).then((result) => {
      if (result.status === 400) {
        Sentry.captureException(Error(JSON.stringify(result.data.error)));
        convertBlobToJson(result.data);
      } else if (result.data) {
        if (result.data.type === "application/json") {
          const fr = new FileReader();
          fr.onload = function () {
            let data = JSON.parse(this.result);
            if (data.status === true && data.data === true) {
              setTextMessageMailingModal(t("modals.titleInvoicePrinting"));
              setMessageMailingModal(t("modals.textPrintInvoices")); //print invoices
              if (!omit) {
                setShowMailingModal(true);
              }
            }
          };
          fr.readAsText(result.data);
        }
      }
      setIsLoadingInvoices(false);
    });
  };

  /**
   * Purpose: Download a .zip file, where the data of the invoices is sent by ids
   */
  const downloadInvoices = (paymentIds, omit = false) => {
    setIsLoadingInvoices(true);
    Array.isArray(paymentIds) ? null : (paymentIds = []);
    if (!paymentIds.length) {
      if (selectedRowIds.length && selectedRowIds[0].original) {
        for (let i = 0; i < selectedRowIds.length; i++) {
          paymentIds.push(selectedRowIds[i].original.id);
        }
      }
    }
    setIdsPrint(paymentIds);
    setActionType("dowload");
    paymentIds = JSON.stringify(paymentIds);
    let data = { payment_ids: paymentIds };
    if (omit) {
      data.ignore_not_billed = true;
    }
    DownloadMultiple(data).then((result) => {
      if (result.status === 400) {
        Sentry.captureException(Error(JSON.stringify(result.data.error)));
        convertBlobToJson(result.data);
      } else {
        if (result.data.type === "application/json") {
          const fr = new FileReader();
          fr.onload = function () {
            let data = JSON.parse(this.result);
            if (data.status === true && data.data === true) {
              setTextMessageMailingModal(t("modals.titleDowloadInvoice"));
              setMessageMailingModal(t("modals.textDowloadInvoiceModal")); //download invoices
              if (!omit) {
                setShowMailingModal(true);
              }
            }
          };
          fr.readAsText(result.data);
        } else {
          //Create Blob and download
          let blob = new Blob([result.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Invoices" + moment().format("DDMMYYYY") + ".zip";
          link.click();
          clearInvoiceDataModal();
        }
      }
      setIsLoadingInvoices(false);
    });
  };

  /**
   * Purpose: Download a .zip file, where the receipt data is sent by ids.
   */
  const downloadReceipts = () => {
    setIsProcessing(true);
    let paymentIds = [];
    if (selectedRowIds.length && selectedRowIds[0].original) {
      for (let i = 0; i < selectedRowIds.length; i++) {
        paymentIds.push(selectedRowIds[i].original.id);
      }
    }
    paymentIds = JSON.stringify(paymentIds);
    DownloadMultipleReceipts(paymentIds).then((result) => {
      //It works for when the service knows that you are bringing a lot of payments and sends a modal if it does not do your normal job.
      if (result.type === "application/json") {
        const fr = new FileReader();
        fr.onload = function () {
          let obj = JSON.parse(this.result);
          if (obj.status === true && obj.data === true) {
            setTextMessageMailingModal(t("modals.titleModalMailing"));
            setMessageMailingModal(t("modals.textDowloadReceipts")); //download receipts
            setShowMailingModal(true);
          }
        };
        fr.readAsText(result);
      } else {
        Sentry.captureException(Error(JSON.stringify(result)));
      }
      setIsProcessing(false);
    });
  };

  /**
   * Purpose: Convert blob file to json
   * @param {Obj} value
   */
  const convertBlobToJson = (value) => {
    const fr = new FileReader();
    fr.onload = function () {
      let error = JSON.parse(this.result);
      const objError = JSON.parse(error.error.description);
      setBiillablePayments(objError.billable);
      setNonBillablePayments(objError.non_billable);
      if (objError.non_billable.length) {
        setShowModalPaymentsNonEligibleForBilling(true);
      } else if (objError.billable.length) {
        setShowModalUnbilledPayments(true);
      }
    };
    fr.readAsText(value);
  };

  /**
   * Prupose: Get the payments
   * @param {Obj} filters
   */
  const getPayments = (filters = requestFilter) => {
    setRequestFilter(filters);
    setLoading(true);
    GetPayments(filters).then((result) => {
      if (result && result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        setError(result.description);
      } else {
        let data = result.data.data;
        let paymentIds = [];
        for (let payment of data) {
          const method =
            payment.payment_method && payment.payment_method.toLowerCase();
          paymentIds.push(payment.id);
          payment.enrollment = payment.curp_or_student_unique_id;
          payment.linkToPage = `${process.env.REACT_APP_V1}/payment_report/${payment.confirmation}/transactions`;
          payment.name = payment.student_name;
          payment.status = payment.status_code;
          payment.transaction = payment.confirmation;
          payment.rfcIssuer = payment.rfc_issuer;
          payment.rfcReceiver = payment.rfc_receiver;
          payment.allowBilling =
            payment.allow_billing == "1" ? t("global:yes") : t("global:no");
          payment.paymentMethod = method ? t(`paymentMethods.${method}`) : "";
          let dateMoment = moment.unix(payment.create_date);
          payment.date = dateMoment.format("DD/MM/YYYY");
          let key = language == "spanish" ? "valueLabelEs" : "valueLabelEn";
          let label = status.find(
            (status) => status.value === payment.status_code
          );
          payment.labelStatus = label[key];
          payment.index = payment.id;
          payment.onlinePayment =
            payment.online_payment == "1" ? t("global:yes") : t("global:no");
          payment.cardNumber = payment.card_number;
          // Validating receiver_user param
          payment.receiver_user = payment.receiver_user || "-";
          for (let param in payment) {
            payment[param] =
              payment[param] != null && payment[param] != ""
                ? payment[param]
                : "N/A";
          }
        }
        setAllPaymentIds(paymentIds);
        setDataTable(data);
      }
      //Add status options in the filter
      setStatusOptions([
        {
          value: "completed",
          label: t("status.paidOut"),
          id: t("status.paidOut"),
        },
        {
          value: "in_progress",
          label: t("status.pending"),
          id: t("status.pending"),
        },
        {
          value: "refunded",
          label: t("status.refunded"),
          id: t("status.refunded"),
        },
        {
          value: "cancelled",
          label: t("status.cancelled"),
          id: t("status.cancelled"),
        },
        {
          value: "charge_pending",
          label: t("status.chargePending"),
          id: t("status.chargePending"),
        },
        {
          value: "expired",
          label: t("status.expired"),
          id: t("status.expired"),
        },
        {
          value: "failed",
          label: t("status.failedStatus"),
          id: t("status.failedStatus"),
        },
      ]);
      setLoading(false);
      setLoadingView(false);
    });
  };

  /**
   * Used to clean selected filters
   */
  const cleanRequestFilters = () => {
    let filters = {};
    getPayments(filters);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("headerTable.internalEnrollment"),
        accessor: "enrollment",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: dataTable.length && count != 0 ? 100 : 130,
        width: 140,
        maxWidth: 150,
      },
      {
        Header: t("headerTable.name"),
        accessor: "name",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 130,
        width: 200,
        maxWidth: 250,
      },
      {
        Header:
          widthSize <= mobileSize
            ? t("address:labels.state")
            : t("headerTable.status"),
        accessor: "labelStatus",
        Filter: (props) => (
          <SelectFilter
            defaultValues={statusOptions}
            {...props}
            header={t("headerTable.status")}
          />
        ),
        typeElement: "label",
        Cell: ({ cell: { value } }) => {
          let key = language == "spanish" ? "valueLabelEs" : "valueLabelEn";
          let label = status.find((status) => status[key] === value);

          return (
            <Label
              values={label[key]}
              bg={label ? label.classStyle : ""}
              style={{ width: "95%" }}
            />
          );
        },
        minWidth: 120,
        width: 140,
        maxWidth: 160,
      },
      {
        Header: t("headerTable.transaction"),
        accessor: "transaction",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 110,
        width: 200,
        maxWidth: 250,
      },
      {
        Header: t("headerTable.paymentMethod"),
        accessor: "paymentMethod",
        Filter: (props) => <SelectFilter {...props} />,
        minWidth: 120,
        width: 120,
        maxWidth: 140,
      },
      {
        Header: t("headerTable.card"),
        accessor: "cardNumber",
        Filter: (props) => <SelectFilter {...props} />,
        minWidth: 70,
        width: 80,
        maxWidth: 100,
      },
      {
        Header: t("headerTable.onlinePayment"),
        accessor: "onlinePayment",
        Filter: (props) => <SelectFilter {...props} />,
        minWidth: dataTable.length && count != 0 ? 80 : 110,
        width: 120,
        maxWidth: 150,
      },
      {
        Header: t("headerTable.received_by"),
        accessor: "receiver_user",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: dataTable.length && count != 0 ? 100 : 130,
        width: 200,
        maxWidth: 250,
      },
      {
        Header: t("headerTable.rfcIssuer"),
        accessor: "rfc_issuer",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: dataTable.length && count != 0 ? 80 : 120,
        width: 140,
        maxWidth: 160,
      },
      {
        Header: t("headerTable.rfcReceiver"),
        accessor: "rfc_receiver",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: dataTable.length && count != 0 ? 100 : 120,
        width: 140,
        maxWidth: 160,
      },
      {
        Header: t("headerTable.date"),
        accessor: "date",
        Filter: (props) => (
          <DateRangeFilter
            getByDateRange={filterHandler}
            lastNDays={{
              start_date: moment().subtract(30, "day").startOf("day").unix(),
              end_date: moment().endOf("day").unix(),
              label: t("status.lastThirtyDay"),
              selectedByDefault: true,
            }}
            {...props}
          />
        ),
        filter: "dateBetween",
        minWidth: 80,
        width: 120,
        maxWidth: 140,
      },
      {
        Header: t("headerTable.amount"),
        accessor: "amount",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 80,
        width: 120,
        maxWidth: 150,
      },
      {
        Header: t("headerTable.allowBilling"),
        accessor: "allowBilling",
        Filter: (props) => (
          <SelectFilter defaultValues={allowBillingOptions} {...props} />
        ),
        minWidth: dataTable.length && count != 0 ? 70 : 100,
        width: 120,
        maxWidth: 180,
      },
    ],
    [loading]
  );

  /**
   * Purpose: To manage where the filters come from to make the request.
   * @param {Obj} event  // Event containing the value of the selected filter
   * @param {Obj} column // Contains the object of the filter/column origin
   */
  const filterHandler = (event, column = false) => {
    let value = null;
    if (event.target) {
      event.target.value != "" ? (value = event.target.value) : null;
    } else if (event.valueOption) {
      value = event.valueOption;
    } else {
      value = event.value;
    }
    let origen = column.id;
    let filters = {};
    switch (origen) {
      case "enrollment":
        value != null
          ? (filters.enrollment = value)
          : delete filters.enrollment;
        break;
      case "name":
        value != null ? (filters.name = value) : delete filters.name;
        break;
      case "labelStatus":
        value != null
          ? (filters.status_code = value)
          : delete filters.status_code;
        break;
      case "transaction":
        value != null
          ? (filters.confirmation = value)
          : delete filters.confirmation;
        break;
      case "rfc_issuer":
        value != null
          ? (filters.rfc_issuer = value)
          : delete filters.rfc_issuer;
        break;
      case "rfc_receiver":
        value != null
          ? (filters.rfc_receiver = value)
          : delete filters.rfc_receiver;
        break;
      case "amount":
        value != null ? (filters.amount = value) : delete filters.amount;
        break;
      case "date":
        if (event.start_date) {
          filters.start_date = event.start_date;
          filters.end_date = event.end_date;
        } else {
          delete filters.start_date;
          delete filters.end_date;
        }
        break;
      default:
    }
    getPayments(filters);
  };

  /**
   * Purpose: Generates the PDF of the payment receipts selected in the table
   */
  const getPdfReceipts = () => {
    setIsProcessing(true);
    let paymentIds = [];
    if (selectedRowIds.length && selectedRowIds[0].original) {
      for (let i = 0; i < selectedRowIds.length; i++) {
        paymentIds.push(selectedRowIds[i].original.id);
      }
    } else {
      paymentIds = allPaymentIds;
    }
    GetPdfReceipts(paymentIds).then((response) => {
      //It works for when the service knows that you are bringing a lot of payments and sends a modal if it does not do your normal job.
      if (response.type === "application/json") {
        const fr = new FileReader();
        fr.onload = function () {
          let obj = JSON.parse(this.result);
          if (obj.status === true && obj.data === true) {
            setTextMessageMailingModal(t("modals.titleModalMailing"));
            setMessageMailingModal(t("modals.textPrintReceipts")); //print receipts
            setShowMailingModal(true);
          }
        };
        fr.readAsText(response);
      } else {
        Sentry.captureException(Error(JSON.stringify(response)));
      }
      setIsProcessing(false);
    });
  };

  //Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => dataTable, [loading]);

  /**Initial loading */
  useEffect(() => {
    Sentry.setTag("section", "Transactions/invoices");
    let dataFilter = JSON.parse(
      localStorage.getItem("filtersMovementsInvoices")
    );
    let dataHiddenColumns = JSON.parse(
      localStorage.getItem("hiddenColumnsMovementsInvoices")
    );
    if (dataFilter) {
      setFilters(dataFilter);
      localStorage.removeItem("filtersMovementsInvoices");
    }
    if (dataHiddenColumns) {
      setHiddenColumns(dataHiddenColumns);
      localStorage.removeItem("hiddenColumnsMovementsInvoices");
    }
    //It is used for when there is a date filter in the applied localStorage, if the default date is not applied
    if (dataFilter && dataFilter[0].id === "date") {
      let obj = {
        end_date: dataFilter[0].value.endDate,
        start_date: dataFilter[0].value.startDate,
        additional_fields: "receiver_user",
      };
      filterHandler(obj, dataFilter[0]);
    } else {
      let requestFilters = {
        start_date: moment().subtract(1, "month").startOf("day").unix(),
        end_date: moment().endOf("day").unix(),
        additional_fields: "receiver_user",
      };
      getPayments(requestFilters);
    }
  }, []);

  /*
   *Purpose: To block the invoice button when the check is selected and you see if you have invoices or have not yet invoiced.
   */
  useEffect(() => {
    let selectAll = {
      switch: selectAllRows.switch,
      value: isAllRowsSelected,
    };
    setSelectAllRows(selectAll);
    let value = false;
    selectedRowIds.forEach((paymentSelected) => {
      let selectedId = paymentSelected.original.id;
      let payment = data.find((element) => element.id == selectedId);
      if (payment != -1) {
        value = !value
          ? payment.status != "completed" ||
          payment.has_invoice === "0" ||
          payment.rfc_issuer === null ||
          payment.rfc_receiver === null
          : value;
        setDisabledActions(value);
      }
    });
  }, [selectedRowIds]);

  window.onresize = () => {
    setWidthSize(window.innerWidth);
    setBtnMarginR(
      window.innerWidth <= mobileSize
        ? { marginRight: "0px" }
        : { marginRight: "10px" }
    );
    if (window.innerWidth <= mobileSize) {
      hideMobileViewColumns();
    }
  };

  //Hide for first listing call
  useEffect(() => {
    if (window.innerWidth <= mobileSize) {
      hideMobileViewColumns();
    }
  }, []);

  const hideMobileViewColumns = () => {
    if (permissions.students_list.access) {
      setHiddenColumns([
        "enrollment",
        "name",
        "transaction",
        "rfc_issuer",
        "rfc_receiver",
      ]);
    }
    if (permissions.organization_admin_students.access) {
      setHiddenColumns([
        "enrollment",
        "name",
        "transaction",
        "rfc_issuer",
        "rfc_receiver",
      ]);
    }
  };

  /**
   * Objective: Redirect to payment list
   * @param {String} path
   */
  const goApp1 = (path) => {
    var now = new Date();
    now.setDate(now.getDate() + 5);
    goToHref(`/${path}`);
  };

  /**
   * Purpose: Action when you click on a table row
   * @param {Obj} payment // Is an object with movement parameters.
   * @param {Obj} state //Object that stores the state of filters and hidden columns.
   */
  const rowOnclick = (payment, state) => {
    let filters = state.filters;
    let hiddenColumns = state.hiddenColumns;
    localStorage.setItem("filtersMovementsInvoices", JSON.stringify(filters));
    localStorage.setItem(
      "hiddenColumnsMovementsInvoices",
      JSON.stringify(hiddenColumns)
    );
    goApp1(
      "payment_report/" + payment.confirmation + "/" + props.defaultTabView
    );
  };

  /**
   * Purpose: Action that cleans the modal data.
   */
  const clearInvoiceDataModal = () => {
    setIdsPrint([]);
    setConfirmations([]);
    setBiillablePayments([]);
    setNonBillablePayments([]);
  };

  const generateReportSap = (type) => {
    setSapReportSAP({
      showModalReportSAP: true,
      type: type,
    })
  }

  return permissions.payments_transactions.access ? (
    <div>
      <Row style={{ marginBottom: "20px" }}>
        {/** Section for Print and Export buttons */}
        <Col xs={12} md={12} style={{ position: "relative" }}>
          <div className={widthSize <= mobileSize ? "d-grid gap-2" : ""}>
            <Button
              variant="outline-secondary"
              onClick={() => {
                let selectAll = {
                  switch: !selectAllRows.switch,
                  value: !selectAllRows.value,
                };
                setSelectAllRows(selectAll);
              }}
              style={btnMarginR}
            >
              {!isAllRowsSelected
                ? t("global:buttons.selectAll")
                : t("global:buttons.unselectAll")}
            </Button>
            {/* Total counter of records and selected records in Transactions Report table */}
            {selectedRowIds.length ? (
              selectedRowIds.length == 1 ? (
                <p className="d-inline" style={customStyles.textDangerBold}>
                  {t("table:textTableViews.selectedRecord", {
                    countSelected: selectedRowIds.length,
                  })}
                </p>
              ) : (
                <p className="d-inline" style={customStyles.textDangerBold}>
                  {t("table:textTableViews.selectedRecords", {
                    countSelected: selectedRowIds.length,
                  })}
                </p>
              )
            ) : (
              <p className="d-inline" style={customStyles.textGrayBold}>
                {t("table:textTableViews.totalRecords", {
                  count: count,
                })}
              </p>
            )}

            <div className="float-end">
              <Dropdown
                className={
                  widthSize <= mobileSize
                    ? "d-grid gap-2"
                    : "d-md-inline-block me-2"
                }
                style={
                  widthSize <= mobileSize
                    ? { marginRight: "0px" }
                    : { marginRight: "10px", display: "inline-block" }
                }
              >
                <Dropdown.Toggle
                  id="downloadOption"
                  variant="outline-secondary"
                  disabled={printExport}
                >
                  <i className="bi bi-download"></i>
                  {t("global:buttons.download")}
                </Dropdown.Toggle>

                <Dropdown.Menu disabled={printExport}>
                  <ReactToPrint
                    trigger={() => (
                      <Dropdown.Item>
                        {t("actionOptions.downloadPDF")}
                      </Dropdown.Item>
                    )}
                    content={() => componentRef.current}
                  />
                  <ExportToExcel
                    exportData={exportData}
                    exportOptions={exportOptions}
                    printExport={printExport}
                    typeElement="dropdownItem"
                  />
                  {(services.SAP && services.SAP.active) && permissions.sap_reports?.access && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item disabled>
                        {t("reports:optionsSap.sapReporting")}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => generateReportSap("csvDay")} >
                        {t("reports:optionsSap.csvCuttingPolicy")}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => generateReportSap("txtDay")}>
                        {t("reports:optionsSap.cuttingPolicyTxt")}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => generateReportSap("csvMonth")}>
                        {t("reports:optionsSap.csvSummary")}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => generateReportSap("txtMonth")}>
                        {t("reports:optionsSap.summaryAccountingReport")}
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {permissions.payments_transactions.create ? (
                <>
                  {!selectedRowIds.length ? (
                    <div
                      className={
                        widthSize <= mobileSize
                          ? "d-grid gap-2"
                          : "d-md-inline-block me-2"
                      }
                      style={
                        widthSize <= mobileSize
                          ? { marginRight: "0px", marginTop: "10px" }
                          : { marginRight: "10px", display: "inline-block" }
                      }
                    >
                      <a
                        href="?massBilling=true"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          style={btnMarginR}
                          variant="primary"
                          onClick={() => setShowMassBillingModal(true)}
                          disabled={isLoadingInvoices}
                          size="lg"
                        >
                          {t("global:buttons.massiveInvoicing")}
                        </Button>
                      </a>
                    </div>
                  ) : (
                    <div className="d-md-inline-block">
                      <Dropdown
                        className={
                          widthSize <= mobileSize
                            ? "d-grid gap-2"
                            : "d-md-inline-block me-2"
                        }
                        style={
                          widthSize <= mobileSize
                            ? { marginRight: "0px", marginTop: "10px" }
                            : { marginRight: "10px", display: "inline-block" }
                        }
                      >
                        <Dropdown.Toggle
                          id="optionsReceipts"
                          variant="primary"
                          disabled={printExport || isProcessing}
                        >
                          {isProcessing ? (
                            <TextLoading
                              text={t("global:buttons.processing")}
                              variant="dark"
                            />
                          ) : (
                            <>
                              <i className="bi bi-receipt-cutoff"></i>
                              {t("actionOptions.textReceipts")}
                            </>
                          )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu disabled={printExport}>
                          <Dropdown.Item
                            disabled={isLoadingInvoices}
                            href="#"
                            onClick={() => getPdfReceipts()}
                          >
                            {t(actionOptions[0].label)}
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={printExport}
                            href="#"
                            onClick={() => downloadReceipts()}
                          >
                            {t(actionOptions[1].label)}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown
                        className={
                          widthSize <= mobileSize
                            ? "d-grid gap-2"
                            : "d-md-inline-block "
                        }
                        style={
                          widthSize <= mobileSize
                            ? { marginRight: "0px", marginTop: "10px" }
                            : { marginRight: "10px", display: "inline-block" }
                        }
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={
                          printExport || disabledActions
                            ? t("modals.textDisabledBtn")
                            : false
                        }
                      >
                        <Dropdown.Toggle
                          id="optionInvoices"
                          variant="primary"
                          disabled={isLoadingInvoices}
                        >
                          {isLoadingInvoices ? (
                            <TextLoading
                              text={t("global:buttons.processing")}
                              variant="dark"
                            />
                          ) : (
                            <>
                              <img
                                src="/img/buttonIcons/file_xml.svg"
                                style={{ height: "14px", marginRight: "4px" }}
                              />
                              {t("headerTable.invoices")}
                            </>
                          )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {actionOptions.map((status, i) => {
                            return (
                              <Dropdown.Item
                                href="#"
                                key={i}
                                onClick={
                                  i == 0 ? printInvoices : downloadInvoices
                                }
                              >
                                {t(status.label)}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
      {loadingView ? (
        <ContentSolid style={{ marginTop: "20px" }}>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <>
          {error}
          <ContentTable startColumFilter={1} lastColumFilter={8}>
            <Table
              ref={componentRef}
              columns={columns}
              data={data}
              loading={loading}
              rowSelect={true}
              setExportData={setExportData}
              setPrintExport={setPrintExport}
              hiddenColumns={hiddenColumns} //Indicates the columns that will be hidden by default (The array contains the "accessors" of the columns)
              showEditColumns={showEditColumns} //Flag for the modal "Edit List Columns"
              setShowEditColumns={setShowEditColumns} //Toggle function of the "Edit List Columns" modal
              setSelectedRowIds={setSelectedRowIds}
              rowOnclick={rowOnclick}
              filters={filters}
              cleanRequestFilters={cleanRequestFilters}
              selectAllRows={selectAllRows}
              setIsAllRowsSelected={setIsAllRowsSelected}
              showColumnGear={permissions.organization_admin_students.access}
              isLinked={true}
              setRowsDisplayed={setCount}
            />
          </ContentTable>
        </>
      )}
      <MassBillingModal
        showMassBillingModal={showMassBillingModal}
        setShowMassBillingModal={setShowMassBillingModal}
        setShowModalInvoicePrinting={setShowModalInvoicePrinting}
      />
      <ModalPaymentsNonEligibleForBilling
        showModalPaymentsNonEligibleForBilling={
          showModalPaymentsNonEligibleForBilling
        }
        setShowModalPaymentsNonEligibleForBilling={
          setShowModalPaymentsNonEligibleForBilling
        }
        nonBillablePayments={nonBillablePayments}
        setShowModalUnbilledPayments={setShowModalUnbilledPayments}
        setIdsPrint={setIdsPrint}
        idsPrint={idsPrint}
        billablePayments={billablePayments}
        downloadInvoices={downloadInvoices}
        printInvoices={printInvoices}
        confirmations={confirmations}
        setConfirmations={setConfirmations}
        actionType={actionType}
        clearInvoiceDataModal={clearInvoiceDataModal}
      />
      <ModalUnbilledPayments
        showModalUnbilledPayments={showModalUnbilledPayments}
        setShowModalUnbilledPayments={setShowModalUnbilledPayments}
        billablePayments={billablePayments}
        setShowModalPaymentsNonEligibleForBilling={
          setShowModalPaymentsNonEligibleForBilling
        }
        nonBillablePayments={nonBillablePayments}
        idsPrint={idsPrint}
        setIdsPrint={setIdsPrint}
        downloadInvoices={downloadInvoices}
        printInvoices={printInvoices}
        confirmations={confirmations}
        setConfirmations={setConfirmations}
        actionType={actionType}
        getPayments={getPayments}
        clearInvoiceDataModal={clearInvoiceDataModal}
        setShowMailingModal={setShowMailingModal}
        setShowModalInvoicePrinting={setShowModalInvoicePrinting}
      />
      <MailingModal
        showMailingModal={showMailingModal}
        setShowMailingModal={setShowMailingModal}
        messageMailingModal={messageMailingModal}
        textMessageMailingModal={textMessageMailingModal}
      />
      <ModalInvoicePrinting
        showModalInvoicePrinting={showModalInvoicePrinting}
        setShowModalInvoicePrinting={setShowModalInvoicePrinting}
        actionType={actionType}
      />
      {sapReportSAP.showModalReportSAP &&
        <ModalReportSAP
          sapReportSAP={sapReportSAP}
          setSapReportSAP={setSapReportSAP}
        />
      }
    </div>
  ) : (
    goToHref("/landing_page")
  );
};

export default TransactionsReportView;

TransactionsReportView.propTypes = {
  cell: PropTypes.func,
  state: PropTypes.object,
  defaultTabView: PropTypes.string,
};
