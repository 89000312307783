/**
 *       **** Readme ****
 *
 * Component: <FormSchoolCycle />
 * Purpose: Returns the Select element.
 *
 * Props:
 *   -required: {Boolean} Indicates if the field is required
 *   -showRequired: {Boolean} Indicates whether the "*" or the "optional" text is displayed
 *
 *
 * Creation date: 01/March/2022
 * Last update: 04/April/2022
 */

import React, { useEffect, useState } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import Select from "react-select";
import { Form, Button, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

//Components
import selectStyles from "../GlobalTools";
import CreateCycleModal from "../../../pages/cycles/modals/CreateCycleModal";
import FormSchoolLevel from "./FormSchoolLevel";

//API
import { GetSchoolCycle } from "../../../api/Cycles";

export default function FormSchoolCycle({ label, ...props }) {
  const [t] = useTranslation(["cycles"]);
  const [field, meta, helpers] = useField(props);

  const { useIn } = props;
  const [addButton] = useState(props.addButton || false);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //Create Cycle Modal
  const [openCreateCycleModal, setCreateCycleModal] = useState(false);
  const [organizationSchoolLevels, setOrganizationSchoolLevels] = useState([]);
  const [programsIds, setProgramsIds] = useState([]);

  /**
   * Purpose: Toggle to open and close "CreateCycleModal"
   */
  const toggleCreateCycle = () => {
    if (props.setSomeModalOpen) {
      //Save the value to know if any modal is open
      props.setSomeModalOpen(!openCreateCycleModal);
    }
    setCreateCycleModal(!openCreateCycleModal);
  };

  //End Create Cycle Modal

  /**
   * Get current leves. //Este es para formuluarios
   * @param {Object} currentSchoolCycle
   */
  const getCurrentLevels = (currentSchoolCycle) => {
    //Extract the levels of the school cycle in use that belong to the organization.
    let organizationSchoolLevels = [];
    for (let singleLevel of currentSchoolCycle.school_levels) {
      let schoolLevel = {};
      schoolLevel.organization_school_level_id =
        singleLevel.organization_school_level_id;
      organizationSchoolLevels.push(schoolLevel);
    }
    //Extract the higher levels of the school cycle in use that belong to the organization.
    let programsIds = [];
    for (let singleColleges of currentSchoolCycle.colleges_and_schools) {
      let collegesLevels = singleColleges.school_levels;
      for (let singleLevel of collegesLevels) {
        let levelPrograms = singleLevel.programs;
        for (let singleProgram of levelPrograms) {
          if (singleProgram.enabled === "1") {
            programsIds.push(singleProgram.id);
          }
        }
      }
    }
    setOrganizationSchoolLevels(organizationSchoolLevels);
    setProgramsIds(programsIds);
  };

  /**
   * Purpose: Gets the school cycle. //Este es para ambos
   */
  const getSchoolCycle = () => {
    setIsLoading(true);
    GetSchoolCycle().then((result) => {
      let data = result.data;
      //setAllDataSchoolCycles(data); //Esta haciendo lo mismo que setOptions, contienen exactamente lo mismo, la que se usará sera setOptions
      for (let index = 0; index < data.length; index++) {
        data[index].label = data[index].school_cycle;
        data[index].value = data[index].id;
      }
      setOptions(data);

      //Get the school cycles
      let currentSchoolCycle = {};
      currentSchoolCycle = data.find((cycle) => cycle.current_cycle === "1");
      currentSchoolCycle = currentSchoolCycle
        ? currentSchoolCycle
        : data.length
        ? data[0]
        : false;

      if (currentSchoolCycle) {
        getCurrentLevels(currentSchoolCycle);
      }
      setIsLoading(false);
    });
  };

  /**
   * Initial loading
   */
  useEffect(() => {
    getSchoolCycle();
  }, []);

  return (
    <>
      {/** School cycle component for filters only */}
      {useIn === "filters" ? (
        <Select
          options={options}
          onChange={props.handleOnchange}
          placeholder={t("select.placeholder")}
          styles={selectStyles}
          formatOptionLabel={function (data) {
            return <span dangerouslySetInnerHTML={{ __html: data.label }} />;
          }}
          isDisabled={isLoading}
          isClearable
        />
      ) : (
        ""
      )}

      {/** School Cycle Component for forms */}
      {useIn === "form" && addButton ? (
        <Form.Group className="mb-3">
          <Form.Label
            htmlFor={props.id || props.name}
            className={props.required && props.showRequired ? "required" : ""}
          >
            {label}
            {!props.required && props.showRequired === false ? (
              <span className="optional">{t("optional")}</span>
            ) : (
              ""
            )}
          </Form.Label>
          <InputGroup className="mb-3">
            <Select
              classNamePrefix={"select-group"}
              className={
                meta.touched && meta.error
                  ? `select-group errorValidation`
                  : `select-group`
              }
              options={options}
              placeholder={t("select.placeholder")}
              styles={selectStyles}
              formatOptionLabel={function (data) {
                return (
                  <span dangerouslySetInnerHTML={{ __html: data.label }} />
                );
              }}
              isDisabled={isLoading}
              isClearable
              {...field}
              {...props}
              onBlur={() => helpers.setTouched(true)}
            />
            <Button
              variant="primary"
              id="button-addon2"
              disabled={isLoading}
              onClick={toggleCreateCycle}
            >
              {/*You have to open the modal to create cycle*/}
              {t("select.button")}
            </Button>
            {meta.touched && meta.error ? (
              <Form.Text
                className="text-muted error"
                style={{ bottom: "-17px" }}
              >
                {meta.error}
              </Form.Text>
            ) : null}
          </InputGroup>
        </Form.Group>
      ) : useIn === "form" ? (
        <Form.Group className="mb-3">
          <Form.Label
            htmlFor={props.id || props.name}
            className={props.required && props.showRequired ? "required" : ""}
          >
            {label}
            {!props.required && props.showRequired === false ? (
              <span className="optional">{t("optional")}</span>
            ) : (
              ""
            )}
          </Form.Label>
          <Select
            classNamePrefix={"select"}
            className={
              meta.touched && meta.error ? `select errorValidation` : `select`
            }
            options={options}
            placeholder={t("select.placeholder")}
            styles={selectStyles}
            isDisabled={isLoading}
            isClearable
            {...field}
            {...props}
            onBlur={() => helpers.setTouched(true)}
          />
          {meta.touched && meta.error ? (
            <Form.Text className="text-muted error">{meta.error}</Form.Text>
          ) : null}
        </Form.Group>
      ) : (
        ""
      )}

      {/** Shows the elements of Schooling-Grade-Group || Schooling-Academic offer-Level */}
      <FormSchoolLevel
        cycleId={props.value && props.value.id ? props.value.id : props.value} //Selected cycle
        cycles={options} //Cycle list
        getSchoolCycle={getSchoolCycle}
        showRequired={props.showRequired}
        values={props.values}
        selectSchoolLevel={props.selectSchoolLevel}
        selectgrade={props.selectgrade}
        selectGroup={props.selectGroup}
        selectGradeId={props.selectGradeId}
        setSelectSchoolLevel={props.setSelectSchoolLevel}
        setSelectGrade={props.setSelectGrade}
        setSelectGroup={props.setSelectGroup}
        setSelectGradeId={props.setSelectGradeId}
        setSelectLevel={props.setSelectLevel}
        setSomeModalOpen={props.setSomeModalOpen} //Update the value if any modal is open
        addButton={addButton}
      />

      {/**Create Cycle Modal*/}
      <CreateCycleModal
        open={openCreateCycleModal}
        importView={"cycle"}
        handleClose={toggleCreateCycle}
        getSchoolCycleFromComponent={getSchoolCycle}
        organizationSchoolLevels={organizationSchoolLevels}
        setOrganizationSchoolLevels={setOrganizationSchoolLevels}
        programsIds={programsIds}
      />
    </>
  );
}

FormSchoolCycle.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  showRequired: PropTypes.bool,
  values: PropTypes.Object, //Formik Values

  handleOnchange: PropTypes.func,
  handleButton: PropTypes.func,
  addButton: PropTypes.bool,
  useIn: PropTypes.string,

  selectSchoolLevel: PropTypes.object,
  selectgrade: PropTypes.object,
  selectGroup: PropTypes.object,
  selectGradeId: PropTypes.number,
  setSelectSchoolLevel: PropTypes.func,
  setSelectGrade: PropTypes.func,
  setSelectGroup: PropTypes.func,
  setSelectGradeId: PropTypes.func,
  setSelectLevel: PropTypes.func,
  setSomeModalOpen: PropTypes.func, //Update the value if any modal is open
};
