import { React, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { MainContext } from "../../App";

//Design resources
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";

// Componentes
import ClassView from "../../components/class/ClassesView";
import Subjects from "../../components/subjects/Subjects";
import ScoresView from "../../components/class/ScoresView";
/**
 *  Component for managing the content to be displayed through tabs
 * @param {obj} props  {params}: to obtain the subroute
 * @returns
 */
const ClassesAndSubjects = (props) => {
  const [t] = useTranslation(["global", "class"]);
  const path = props.match.path.slice(1);
  const defaultTab = path || "class";
  const [focusedTab, setFocusedTab] = useState(defaultTab);
  const history = useHistory();
  const { permissions } = useContext(MainContext);

  /**Initial loading */
  useEffect(() => {
    //Validates that no subroutes are entered outside the section
    const availableTabs = ["class", "subjects", "scores"];
    if (!availableTabs.includes(defaultTab)) {
      history.push("/class");
    }
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col md={12} className="mx-4">
          <h2> {t("class:main." + focusedTab)}</h2>
        </Col>
      </Row>
      <Tab.Container id="classesAndSubjects" defaultActiveKey={focusedTab}>
        <Nav
          variant="pills"
          activeKey={focusedTab}
          style={{ width: "fit-content", margin: "0 auto" }}
        >
          {permissions.classes.access && (
            <Nav.Item>
              <a
                href="class"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{ textDecoration: "none" }}
              >
                <Nav.Link
                  eventKey="class"
                  onClick={() => {
                    setFocusedTab("class");
                    history.push("/class");
                  }}
                >
                  {t("class:main.class")}
                </Nav.Link>
              </a>
            </Nav.Item>
          )}
          {permissions.classes_subjects.access && (
            <Nav.Item>
              <a
                href="subjects"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{ textDecoration: "none" }}
              >
                <Nav.Link
                  eventKey="subjects"
                  onClick={() => {
                    setFocusedTab("subjects");
                    history.push("/subjects");
                  }}
                >
                  {t("class:main.subjects")}
                </Nav.Link>
              </a>
            </Nav.Item>
          )}
          {permissions.class_score?.access && (
            <Nav.Item>
              <a
                href="scores"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{ textDecoration: "none" }}
              >
                <Nav.Link
                  eventKey="scores"
                  onClick={() => {
                    setFocusedTab("scores");
                    history.push("/scores");
                  }}
                >
                  {t("class:main.scores")}
                </Nav.Link>
              </a>
            </Nav.Item>
          )}
        </Nav>
      </Tab.Container>
      <Row>
        <Col md={12}>
          {focusedTab === "class" && <ClassView />}
          {focusedTab === "subjects" && <Subjects />}
          {focusedTab === "scores" && <ScoresView />}
        </Col>
      </Row>
    </Container>
  );
};

ClassesAndSubjects.propTypes = {
  match: PropTypes.object,
};

export default ClassesAndSubjects;
