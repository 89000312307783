import React, { useEffect, useState } from "react";
import {
  Container,
  Breadcrumb,
  Col,
  Row,
  Button,
  Alert,
} from "react-bootstrap";
import { ReactSortable } from "react-sortablejs";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

import FieldInput from "../../components/global/form/FieldInput";
import { goToHref } from "../../components/global/GlobalTools";
import html2canvas from "html2canvas";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

import ReportCardItemCreator from "../../components/reportCards/ReportCardItemCreator";
import SettingAveragingFormatModal from "./modals/SettingAveragingFormatModal";
import DraggableElements from "../../components/reportCards/DraggableElements";
import AssignmentDetails from "../../components/reportCards/AssignmentDetails";
import ConfirmDeleteModal from "./modals/ConfirmDeleteModal";
import NewTamplateModal from "./modals/NewTamplateModal";
import SaveChangesModal from "./modals/SaveChangesModal";

/** Services */
import { organizationGet } from "../../api/Organizations";
import {
  GetClassLevel,
  GetTemplates,
  CreateTemplate,
  UpdateTemplate,
  GetSchoolCycles,
} from "../../api/ReportCards";

//Custom styles
const customStyles = {
  columnBase: {
    marginTop: "10px",
    height: screen.height - screen.height / 4,
  },
  columnTemplates: {
    marginTop: "10px",
    maxHeight: screen.height - screen.height / 4,
  },
  solidBackground: {
    background: "#FFFFFF",
    padding: "30px 20px",
    minHeight: "100%",
    border: "solid 1px #cccccc",
    borderRadius: "5px",
  },
  dropZone: {
    border: "1px solid #e7e7e7",
    padding: " 5px",
    borderRadius: "8px",
    minHeight: "200px",
  },
  period: {
    border: "solid 1px #cccccc",
    borderRadius: "8px",
  },
  etiqeutaCiclo: {
    backgroundColor: "#07c25f",
    color: "#ffffff",
    borderRadius: "3px",
    paddingLeft: "3px",
    paddingRight: "3px",
  },
};

const cycleDefault = {
  schoolLevels: [],
  grades: [],
  groups: [],
  levelSelected: 0,
  gradeSelected: 0,
  groupSelected: 0,
};
const prefix = process.env.REACT_APP_PREFIX;
const language = localStorage.getItem(`cmLanguage${prefix}`);

const ReportCardsView = (props) => {
  const [t] = useTranslation(["reportCards", "global"]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showNewTamplateModal, setShowNewTamplateModal] = useState(false);
  const [showSettingAveraging, setshowSettingAveraging] = useState(false);
  const [currentCycles, setCurrentCycles] = useState(null);
  const [templateToDelete] = useState(null);
  const [showSaveChanges, setShowSaveChanges] = useState(false);
  const [reloadBaseList, setReloadBaseList] = useState(false);
  const [schoolCycles, setSchoolCycles] = useState([]);
  const [cycleSelected, setCycleSelected] = useState(cycleDefault);
  const [listFormatItems, setListFormatItems] = useState([
    {
      id: "0",
      type: "phantomElement",
      disabled: true,
      averaging: true,
    },
  ]);
  const [backupDefaulsClasses, setBackupDefaulsClasses] = useState([]);
  const [defaulsClasses, setDefaulsClasses] = useState([]);
  const [classFilter, setClassFilter] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [lockItemSorting, setLockItemSorting] = useState(true);
  const [editingGradingFormat, setEditingGradingFormat] = useState({});
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [organizationInfo, setOrganizationInfo] = useState({
    name: "",
    logoUrl: "",
  });
  const [templateInformation, setTemplateInformation] = useState({
    name: language === "english" ? "Blank Template" : "Plantilla en Blanco", //initial default value
    format: "",
    thumbnailUrl: "",
  });
  const [uniqueItems, setUniqueItems] = useState({});
  //Sets the waiting time for a request to be made
  let [typingCallback, setTypingCallback] = useState(null);
  const [saving, setSaving] = useState(false);
  // error handler
  const [error, setError] = useState({ active: false, message: "" });
  const [templateId, setTemplateId] = useState(null);
  const [showObservations, setShowObservations] = useState(false);
  const [visiblePage, setVisiblePage] = useState(1);
  const [numberOfSubjects, setNumberOfSubjects] = useState(0);
  const [availableAllocationValues, setAvailableAllocationValues] =
    useState(false);
  const prefix = process.env.REACT_APP_PREFIX;
  const history = useHistory();
  /**
   * The Block element is extracted to identify if it no longer belongs to the list and remove it.
   * @param {object} evt
   */
  const deleteItem = (evt) => {
    if (!lockItemSorting) {
      return;
    }
    let draggedElement = evt.originalEvent.target;
    // waits for the "to" parameter in chromium-based browsers
    let sortableListElement = evt.originalTarget || evt.to;
    //Check if the element no longer exists in the list when the event occurred was of type dragging
    if (evt.originalEvent.type == "dragend") {
      // remove the item that is dropped out of the group
      if (
        sortableListElement !== draggedElement &&
        !sortableListElement.contains(draggedElement)
      ) {
        setReloadBaseList(true);
        let index = draggedElement.id;
        listFormatItems.splice(index, 1);
        setListFormatItems(listFormatItems);
        setReloadBaseList(false);
        uniqueItemWatchman();
        refreshAvailableClassList();
        // update the subject counter
        subjectCounter(listFormatItems);
      }
    }
  };

  /**
   * Remove an item using the x icon
   * @param {obj} item  represents the item to be deleted
   * @param {number} index determines the position of the element to be deleted
   */
  const removeItemByButton = (item, index) => {
    setReloadBaseList(true);
    listFormatItems.splice(index, 1);
    uniqueItemWatchman();
    setListFormatItems(listFormatItems);
    refreshAvailableClassList();
    // update the subject counter
    subjectCounter(listFormatItems);
    setTimeout(() => {
      setReloadBaseList(false);
    }, 0);
  };

  /**
   * gets the classes used in the list and disables them from the possible choices
   */
  const refreshAvailableClassList = (
    lstFormatItems = false,
    defClassesParam = false
  ) => {
    let listItems = lstFormatItems || [...listFormatItems];
    let defClasses = defClassesParam || [...defaulsClasses];
    const result = listItems.filter(
      (item) => item.type === "subject" && item.value
    );
    defClasses.forEach((element) => {
      element.options.forEach((sClass) => (sClass.isDisabled = false));
    });
    for (let subject of result) {
      for (let group of defClasses) {
        let found = group.options.findIndex(
          ({ value }) => value === subject.value.value
        );
        if (found != -1) {
          group.options[found].isDisabled = true;
        }
      }
    }
  };

  /**
   * Listens when a new item is added to the list and removes the dependency on the parent list
   * @param {array} list  -Arrangement of orderable items
   */
  const updateFormatList = (list) => {
    let newArray = [];
    //remove dependency of subobjects
    for (let item of list) {
      let obj = JSON.parse(JSON.stringify(item));
      newArray.push(obj);
    }
    setListFormatItems(newArray);
  };

  /**
   * Stores the value of the class selected by the AsyncSelect
   * @param {obj} item
   * @param {int} position
   */
  const selectClass = (item, position) => {
    setReloadBaseList(true);
    //save the selected class on the element
    let array = [...listFormatItems];
    array[position].value = item;
    array[position].unicID = item.value;
    setListFormatItems(listFormatItems);
    selectedClasses.push(item.gradeGroupLevelId || item.itemprogramLevelId);
    setSelectedClasses(selectedClasses);
    refreshAvailableClassList();
    setTimeout(() => {
      setReloadBaseList(false);
    }, 0);
  };

  /**
   * Get the information of the current school cycle
   */
  const getCurrentCycle = () => {
    GetSchoolCycles().then((result) => {
      let data = result.data.data;
      let cycles = data.filter((cycle) => cycle.current == 1);
      //format cycles for essential data
      formatCycles(data);
      setCurrentCycles(cycles);
    });
  };

  const formatCycles = (cycles) => {
    const allCycles = cycles.map((cycle) => {
      return {
        id: cycle.id,
        name: cycle.school_cycle,
        collegesAndSchools: cycle.colleges_and_schools,
        schoolLevels: cycle.school_levels,
        grades: [],
        groups: [],
        levelSelected: 0,
        gradeSelected: 0,
        groupSelected: 0,
      };
    });

    if (allCycles.length) {
      //if levels exist, extract the grades and format the levels
      if (allCycles[0].schoolLevels.length) {
        allCycles[0].schoolLevels = allCycles[0].schoolLevels.map((level) => {
          return {
            id: level.id,
            school_level_name: level.school_level_name,
            grades: level.grades,
          };
        });
      }
      //if there are grades, extract the groups
      if (allCycles[0].grades.length) {
        allCycles[0].groups = allCycles[0].grades[0].groups.map((group) => {
          return {
            id: group.id,
            name: group.name,
          };
        });
      }
      setCycleSelected(allCycles[0]);
    }
    setSchoolCycles(allCycles);
  };

  /**
   * Gets classes filtered by name
   * @param {string} input -Text referring to the class name
   * @param {func} callback -Function to return the content to where it is invoked
   * @param {string} filter -Text that locally filters classes by school level
   * @param {int} schoolCycleId -Id of the current cycle, used in the first load of the view
   */
  const getClass = (input = "", callback, filter = classFilter, cycles) => {
    let schoolCycles = currentCycles ? currentCycles : cycles;
    // extract only the ids of the cycles
    let data = {
      only_level_classes: true,
      search: input,
    };

    if (filter.type == "gradeGroupLevelId") {
      data.grade_group_grade_level_id = filter.value;
    } else if (schoolCycles?.length) {
      data.school_cycles_ids = schoolCycles.map((cicle) => cicle.id);
    }

    if (typingCallback) {
      clearTimeout(typingCallback);
    }
    typingCallback = setTimeout(() => {
      GetClassLevel(data).then((result) => {
        if (result.response?.data.error) {
          Sentry.captureException(
            Error(JSON.stringify(result.response.data.error))
          );
          return;
        }
        let data = result.data.data;
        let subjects = [];
        if (filter) {
          data = filterClasses(filter, data, true);
        }
        if (data && data.length) {
          for (let item of data) {
            let options = [];
            for (let singleClass of item.classes) {
              let title =
                singleClass.class_title != "" ? singleClass.class_title : "S/N";
              options.push({
                label: title,
                value: singleClass.id,
                level: item.level,
                gradeLevelId: singleClass.grade_level_id,
                programLevelId: singleClass.program_id,
                schoolCycleId: singleClass.school_cycle_id,
                gradeGroupLevelId: singleClass.grade_group_level_id,
              });
            }
            let group = {
              label: item.level,
              options: options,
            };
            subjects.push(group);
          }
        }
        if (input === "") {
          setDefaulsClasses(subjects);
          setBackupDefaulsClasses(subjects);
        } else {
          subjects = filter.type != "gradeGroupLevelId" ? [] : subjects;
          callback(subjects);
        }
      });
    }, 1000);

    setTypingCallback(typingCallback);
  };
  /**
   * Receives an array of classes to filter by school level
   * @param {object} filter - Contains parameters type (parameters of the class) and value (id of the level)
   * @param {array} classArray - Array of all classes
   * @param {bool} apiOrigin - Identifies if the array comes directly from the api or if it is taken locally
   * @returns {array} - Array of filtered classes
   */
  const filterClasses = (
    filter,
    classArray = defaulsClasses,
    apiOrigin = false
  ) => {
    let filtered = [];
    let propClass = "";
    if (apiOrigin) {
      propClass =
        filter.type == "gradeGroupLevelId"
          ? "grade_group_level_id"
          : "program_id";
    } else {
      propClass =
        filter.type == "gradeGroupLevelId" ? "gradeGroupLevelId" : "programId";
    }
    classArray.forEach((groupClass) => {
      let classes = groupClass.classes || groupClass.options;
      let filteredClass = classes.filter(
        (classItem) => classItem[propClass] == filter.value
      );
      if (filteredClass.length) {
        groupClass.options = filteredClass;
        filtered.push(groupClass);
      }
    });
    return filtered;
  };

  /**
   * Get the templates available for the organization
   */
  const getTemplates = () => {
    GetTemplates().then((result) => {
      let data = result.data.data;
      setTemplateList(data);
    });
  };

  /**
   * Get template information by id
   * @param {int} id template id to get
   */
  const getTemplate = (id = templateId) => {
    GetTemplates({ id }).then((response) => {
      let template = response.data.data[0];
      let filter =
        template.grade_group_grade_level_id || template.programLevelId;
      let typeFilter =
        template.grade_group_grade_level_id != null
          ? "gradeGroupLevelId"
          : "programLevelId";
      let newFilter = {
        type: typeFilter,
        value: filter,
      };
      setClassFilter(newFilter);
      getClass("", false, newFilter, false);
      loadTemplate(template);
    });
  };

  /**
   * Puts a template in edit mode
   * @param {object} template
   */
  const loadTemplate = (template) => {
    // setClassFilter(false);
    setDefaulsClasses(backupDefaulsClasses);
    template.editMode = true;
    setTemplateList(templateList);
    let lstFormatItems = formattingListFormat(template.format);
    setListFormatItems(lstFormatItems);
    refreshAvailableClassList(lstFormatItems, backupDefaulsClasses);
    setTemplateInformation({
      name: template.name,
      id: template.id,
      gradeGroupGradeLevelId: template.grade_group_grade_level_id,
      has_comments: template.has_comments,
      thumbnailUrl: "",
      format: "",
    });
    setShowObservations(template.has_comments == 1);
  };

  /**
   * format the items of the format parameter that comes from the api
   * @param {obj} objFormat
   * @returns {array} array of editable items
   */
  const formattingListFormat = (objFormat) => {
    let format = JSON.parse(objFormat);
    format.forEach((element, index) => {
      if (element.type === "subject") {
        let value = {
          value: element.values.id,
          label: element.values.name,
        };
        format[index].name = element.values.name;
        format[index].value = value;

        //Obtain and apply class filter
        let classFound = false;
        backupDefaulsClasses.every((groupClass) => {
          let classes = groupClass.options;
          classFound = classes.find(
            (singleClass) => singleClass.value == element.values.id
          );
          if (classFound) {
            let typeFilter =
              classFound.gradeLevelId != null
                ? "gradeLevelId"
                : "programLevelId";
            let filter = classFound.gradeLevelId || classFound.programLevelId;
            setDefaulsClasses(
              filterClasses(
                {
                  type: typeFilter,
                  value: filter,
                  schoolCycleId: classFound.schoolCycleId,
                },
                backupDefaulsClasses
              )
            );
            return;
          }
        });
      }
      if (element.type === "average") {
        let averageFormat = element.values.average_format;
        averageFormat.averageNumberFormat = {
          value: averageFormat.average_number_format,
          label:
            averageFormat.average_number_format == "int"
              ? t("reportCards:settingAveragingFormatModal.integerNumbers")
              : t("reportCards:settingAveragingFormatModal.decimalNumbers"),
        };
        averageFormat.gradePeriodFormat = {
          value: averageFormat.grade_period_format,
          label:
            averageFormat.grade_period_format == "int"
              ? t("reportCards:settingAveragingFormatModal.integerNumbers")
              : t("reportCards:settingAveragingFormatModal.decimalNumbers"),
        };
        averageFormat.decimalsToUse = {
          value: averageFormat.decimals_to_use,
          label: averageFormat.decimals_to_use,
        };
        averageFormat.averageRounding = averageFormat.average_rounding;
        averageFormat.doNotRound = averageFormat.do_not_round;

        format[index].values.averageClasses = element.values.average_classes;
        format[index].values.averageFormat = averageFormat;

        format[index].value = averageFormat;

        //mark the classes selected on the basis of the ids array
        let averagingClasses = element.values.average_classes;
        averagingClasses.forEach((classId) => {
          // identify the position of the averaging class
          const classPosition = format.findIndex(
            (item) => item.type == "subject" && item.values.id == classId
          );
          // value to checkbox
          format[classPosition].averaging = true;
        });
      }
    });
    return format;
  };

  /**
   * Take a screenshot of the template editing section
   */
  const takeThumbnail = async () => {
    let node = document.querySelector("#templateContainer");
    let options = {
      allowTaint: true,
      useCORS: true,
      imageTimeout: 0,
      scale: 0.5,
    };
    /**
     * (await) Waits for the promise and then returns the generated value
     * (html2canvas) method captures an element to render it on a canvas
     */
    return await html2canvas(node, options).then((canvas) => {
      //The promise is created where the captured container will be returned in jpg image format in base 64
      let dataURL = canvas.toDataURL("image/jpeg");
      return dataURL;
    });
  };

  /**
   * Retrieves the fragmented data to assemble the template
   */
  const saveTemplate = async () => {
    let template = { ...templateInformation };
    let listItems = [...listFormatItems];
    setSaving(true);
    let items = [];
    let classIdsGroup = [];
    let hasClasses = false;
    //remove item stabilizer if it exists
    let position = listItems.findIndex(({ type }) => type == "phantomElement");
    if (position != -1) {
      listItems.splice(position, 1);
    }
    //remove empty class selectors
    for (let pos = listItems.length - 1; pos >= 0; pos--) {
      if (listItems[pos].type === "subject" && !listItems[pos].value) {
        listItems.splice(pos, 1);
      }
    }
    for (let pos in listItems) {
      let obj = {
        order: parseInt(pos),
        type: listItems[pos].type,
      };
      if (listItems[pos].type === "subject" && listItems[pos].value) {
        // groups the ids of the classes for each average item
        hasClasses = true;
        if (listItems[pos].averaging) {
          classIdsGroup.push(listItems[pos].value.value);
        }
        let subjectValue = {
          id: listItems[pos].value.value,
          name: listItems[pos].value.label,
        };
        obj.values = subjectValue;
        obj.averaging = listItems[pos].averaging;
      }
      if (listItems[pos].type === "average") {
        // The structure expected to be validated by the api is created
        let averageFormat = {
          grade_period_format: listItems[pos].values.averageFormat
            .gradePeriodFormat.value
            ? listItems[pos].values.averageFormat.gradePeriodFormat.value
            : "",
          average_number_format: listItems[pos].values.averageFormat
            .averageNumberFormat.value
            ? listItems[pos].values.averageFormat.averageNumberFormat.value
            : "",
          decimals_to_use: listItems[pos].values.averageFormat.decimalsToUse
            .value
            ? listItems[pos].values.averageFormat.decimalsToUse.value
            : "",
          average_rounding:
            listItems[pos].values.averageFormat.averageRounding != ""
              ? parseFloat(listItems[pos].values.averageFormat.averageRounding)
              : "",
          do_not_round: !!listItems[pos].values.averageFormat.doNotRound,
        };
        obj.values = {
          average_format: averageFormat,
          average_classes: classIdsGroup,
          name: listItems[pos].values.name,
        };
        // reset the group for each average found
        classIdsGroup = [];
      }
      if (listItems[pos].type === "groupAverage") {
        obj.values = listItems[pos].values;
      }
      items.push(obj);
    }
    template.thumbnail_url = await takeThumbnail();
    template.format = JSON.stringify(items);
    // template.school_cycle_id = currentCycleData.id;
    if (classFilter) {
      let levelId =
        classFilter.type == "gradeLevelId"
          ? "grade_level_id"
          : "program_level_id";
      template[levelId] = classFilter.value;
    }
    // if you do not have classes, an alert will be launched
    if (!hasClasses) {
      setShowSaveChanges(false);
      setSaving(false);
      printError(t("reportCards:page.noSubjectSelected"));
      return;
    }
    if (template.gradeGroupGradeLevelId) {
      template.grade_group_grade_level_id = template.gradeGroupGradeLevelId;
      delete template.program_level_id;
    }
    //create or update the template
    template.id ? updateTemplate(template) : createNewTemplate(template);
  };

  /**
   * Method for creating a new template
   * @param {obj} template
   */
  const createNewTemplate = (template) => {
    CreateTemplate(template).then((result) => {
      if (result.error) {
        const errorDescription = formattedErrorTemplate(result.error);
        printError(errorDescription);
        Sentry.captureException(Error(JSON.stringify(result.error)));
        setShowSaveChanges(false);
        setSaving(false);
        return;
      }
      let data = result.data || null;
      if (data) {
        history.push(`/settings/report_cards/${data[0].id}`);
        setAvailableAllocationValues(true);
        setListFormatItems(formattingListFormat(data[0].format));
        setTemplateInformation({
          name: data[0].name,
          id: data[0].id,
          gradeGroupGradeLevelId: data[0].grade_group_grade_level_id,
          has_comments: data[0].has_comments,
          thumbnailUrl: "",
          format: "",
        });
      }
      getTemplates();
      setShowSaveChanges(false);
      setSaving(false);
    });
  };

  /**
   *Method for update a template
   * @param {obj} template
   */
  const updateTemplate = (template) => {
    UpdateTemplate(template).then((result) => {
      if (result.error) {
        const errorDescription = formattedErrorTemplate(result.error);
        printError(errorDescription);
        Sentry.captureException(Error(JSON.stringify(result.error)));
        setShowSaveChanges(false);
        setSaving(false);
        return;
      }
      let data = result.data || null;
      if (data) {
        setListFormatItems(formattingListFormat(data[0].format));
        setTemplateInformation({
          name: data[0].name,
          id: data[0].id,
          gradeGroupGradeLevelId: data[0].grade_group_grade_level_id,
          has_comments: data[0].has_comments,
          thumbnailUrl: "",
          format: "",
        });
      }
      getTemplates();
      setShowSaveChanges(false);
      setSaving(false);
    });
  };

  /*
   * Format error when create/update a template
   * @param {object} { code, description }
   * @return {string}
   */
  const formattedErrorTemplate = ({ code, description }) => {
    const invalidId = t("reportCards:saveChangesModal.error");
    const isErrInvalidIdCode = code === "err_invalid_id";
    const errorDescription = isErrInvalidIdCode ? invalidId : description;
    return errorDescription;
  };

  /**
   * Get the organization's data for displaying the card file
   */
  const getOrganization = () => {
    organizationGet().then((result) => {
      let { name, logo_image_url: logoImageUrl } = result.data.data[0];
      let organizationInfo = {
        name: name,
        logoUrl: logoImageUrl,
      };
      setOrganizationInfo(organizationInfo);
    });
  };

  /**
   * Send to the average configuration object modem assigning the current position.
   * @param {obj} formatAverage
   * @param {int} position
   */
  const editAveragingFormat = (formatAverage, position) => {
    formatAverage.averageFormat.position = position;
    setEditingGradingFormat(formatAverage);
    setshowSettingAveraging(true);
  };

  /**
   * Save the averaging configuration in the item it belongs to
   * @param {obj} formatAverage
   * @param {int} position
   */
  const saveAverageformat = (formatAverage, position) => {
    let array = [...listFormatItems];
    array[position].values.averageFormat = formatAverage;
    setListFormatItems(listFormatItems);
    setTimeout(() => {
      setReloadBaseList(false);
    }, 0);
    setshowSettingAveraging(false);
  };

  /**
   * Change the list of items (classes) in checkbox selection mode for averaging
   * @param {bool} show - Switch to activate or deactivate selection mode
   * @param {int} pos - Indicates the position from which the selection mode will start.
   */
  const changeClassesSelectionMode = (show, pos) => {
    setReloadBaseList(true);
    let index = pos - 1;
    let item = {};
    listFormatItems[pos].selectionMode = show;
    // Change in checkbox mode only the classes below the selected average item position
    do {
      item = listFormatItems[index];
      if (item.type === "subject") {
        listFormatItems[index].selectionMode = show;
      }
      index = index - 1;
    } while (index > -1 && item.type != "average");
    // Disable all items in the list so as not to cross events
    listFormatItems.forEach((item) => (item.disabled = show));
    setListFormatItems(listFormatItems);
    setshowSettingAveraging(!show);
    setLockItemSorting(!show);
    setTimeout(() => {
      setReloadBaseList(false);
    }, 0);
  };

  /**
   * Add or remove class ids from the array used for averaging
   * @param {int} classId
   * @param {int} positionClass - current position of the selected class
   */
  const selectClassesForAveraging = (classId, positionClass) => {
    setReloadBaseList(true);
    let pos = listFormatItems.findIndex(
      (item) => item.type == "average" && item.selectionMode
    );
    listFormatItems[positionClass].averaging =
      !listFormatItems[positionClass].averaging;
    if (listFormatItems[positionClass].averaging) {
      listFormatItems[pos].values.averageClasses.push(classId);
    } else {
      const index = listFormatItems[pos].values.averageClasses.findIndex(
        (averageClass) => averageClass === classId
      );
      listFormatItems[pos].values.averageClasses.splice(index, 1);
    }
    setTimeout(() => {
      setReloadBaseList(false);
    }, 0);
  };

  /**
   * Identify the use of unique items when adding or removing items
   */
  const uniqueItemWatchman = () => {
    //list of unique items
    let availableItems = {
      absences: true,
      groupAverage: true,
    };

    for (let property in availableItems) {
      if (listFormatItems.find((item) => item.type == property)) {
        availableItems[property] = false;
      }
    }
    setUniqueItems(availableItems);
  };

  /**
   * counts the subjects on the list
   */
  const subjectCounter = (list) => {
    let count = list.filter((item) => item.type === "subject").length;
    setNumberOfSubjects(count);
  };

  /**
   * generates rows of ratings according to element type
   * @param {*} type
   */
  const generateRow = (type, index) => {
    let customLine = <Row key={index}></Row>;
    const columns = [1, 2, 3, 4];
    switch (type) {
      case "subject":
        customLine = (
          <Row key={index} style={{ height: "65px" }}>
            {columns.map((v) => {
              return (
                <Col
                  md={3}
                  key={v}
                  style={{
                    textAlign: "center",
                    paddingTop: "15px",
                  }}
                >
                  0
                </Col>
              );
            })}
          </Row>
        );
        break;
      case "blankLine":
        customLine = (
          <Row key={index} style={{ height: "50px" }}>
            {columns.map((v) => {
              return (
                <Col
                  md={3}
                  key={v}
                  style={{
                    textAlign: "center",
                    paddingTop: "5px",
                  }}
                ></Col>
              );
            })}
          </Row>
        );
        break;
      case "dottedLine":
        customLine = (
          <Row key={index} style={{ height: "50px" }}>
            {columns.map((v) => {
              return (
                <Col
                  md={3}
                  key={v}
                  style={{
                    textAlign: "center",
                    paddingTop: "5px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <div
                    style={{ borderTop: "dashed 1px black", marginTop: "14px" }}
                  ></div>
                </Col>
              );
            })}
          </Row>
        );
        break;
      case "average":
        customLine = (
          <Row key={index} style={{ height: "56px" }}>
            {columns.map((v) => {
              return (
                <Col
                  md={3}
                  key={v}
                  style={{
                    textAlign: "center",
                    paddingTop: "10px",
                    overflow: "hidden",
                    paddingLeft: "0px",
                  }}
                >
                  0
                </Col>
              );
            })}
          </Row>
        );
        break;
      case "absences":
        customLine = (
          <Row key={index} style={{ height: "50px" }}>
            {columns.map((v) => {
              return (
                <Col
                  md={3}
                  key={v}
                  style={{
                    textAlign: "center",
                    paddingTop: "5px",
                    overflow: "hidden",
                    paddingLeft: "0px",
                  }}
                >
                  0
                </Col>
              );
            })}
          </Row>
        );
        break;

      case "groupAverage":
        customLine = (
          <Row key={index} style={{ height: "50px" }}>
            {columns.map((v) => {
              return (
                <Col
                  md={3}
                  key={v}
                  style={{
                    textAlign: "center",
                    paddingTop: "5px",
                    overflow: "hidden",
                    paddingLeft: "0px",
                  }}
                >
                  0
                </Col>
              );
            })}
          </Row>
        );
        break;
      default:
    }
    return customLine;
  };

  /**
   * empty all selected classes in items
   */
  const cleanSelectedClasses = () => {
    let items = [...listFormatItems];
    items.forEach((item) => {
      if (item.type === "subject") {
        delete item.value;
        delete item.values;
      }
    });
    setListFormatItems(items);
  };

  /**
   * Effect when adding single items
   */
  useEffect(() => {
    uniqueItemWatchman();
  }, [listFormatItems.length]);

  useEffect(() => {
    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);
    if (
      (templateInformation.id || urlParams.get("group")) &&
      schoolCycles.length
    ) {
      //find the cycle, level, grade and group
      let cycleSelect = { ...cycleSelected };
      for (let cycle of schoolCycles) {
        for (let level of cycle.schoolLevels) {
          for (let grade of level.grades) {
            for (let group of grade.groups) {
              const gg_gl_id = templateInformation.id
                ? templateInformation.gradeGroupGradeLevelId
                : urlParams.get("group");
              if (group.grade_group_grade_level_id === gg_gl_id) {
                cycleSelect = cycle;
                cycleSelect.schoolLevels = cycle.schoolLevels;
                cycleSelect.grades = level.grades;
                cycleSelect.groups = grade.groups;

                cycleSelect.levelSelected = level.id;
                cycleSelect.gradeSelected = grade.id;
                cycleSelect.groupSelected = group.id;

                cycleSelect.gradeName = grade.grade_level;
                cycleSelect.groupName = group.name;

                setCycleSelected(cycleSelect);
                if (urlParams.get("group")) {
                  let newFilter = {
                    type: "gradeGroupLevelId",
                    value: urlParams.get("group"),
                  };
                  getClass("", false, newFilter, false);
                  let template = templateInformation;
                  template.gradeGroupGradeLevelId = urlParams.get("group");
                  setTemplateInformation(template);
                  setClassFilter(newFilter);
                }
                break;
              }
            }
          }
        }
      }
    }
  }, [schoolCycles.length]);

  /**
   * initial effect of a single load
   */
  useEffect(() => {
    Sentry.setTag("section", "Report Card Profile");
    const templateId = props.match.params.id;
    if (templateId != "new") {
      setAvailableAllocationValues(true);
      setTemplateId(templateId);
      getTemplate(templateId);
    }
    getOrganization();
    getCurrentCycle();
  }, []);

  /**
   * Purpose: Redirect to cycle configuration section
   * @param {String} tabView
   */
  const goApp1 = (tabView) => {
    var now = new Date();
    now.setDate(now.getDate() + 5);
    goToHref(`/configuraciones/${tabView}`);
  };

  const printError = (message) => {
    setError({
      active: true,
      message,
    });
    setTimeout(() => {
      setError({
        active: false,
        message: "",
      });
    }, 3500);
  };

  return (
    <div>
      <Container fluid>
        <Breadcrumb style={{ "--bs-breadcrumb-divider": "'>'" }}>
          <Breadcrumb.Item
            style={{ color: "#bfc2c6" }}
            onClick={() => goApp1("ciclo")}
          >
            <i
              style={{ color: "#bfc2c6", fontSize: "15px", marginRight: "5px" }}
              className="bi bi-gear"
            ></i>
            {t("reportCards:page.configuration")}
          </Breadcrumb.Item>
          <Breadcrumb.Item active style={{ color: "#bfc2c6" }}>
            {t("reportCards:page.title")}
          </Breadcrumb.Item>
        </Breadcrumb>
        <h2>{t("reportCards:page.title")}</h2>
        {/* button panel */}
        <Row>
          <Col sm={3} md={3} xl={3} xxl={2}>
            <h6 className="fw-bold">
              <div
                style={{ cursor: "pointer", width: "fit-content" }}
                onClick={() => {
                  if (Cookies.get(`sourceList${prefix}`)) {
                    let domain = window.location.href.includes(
                      ".campusmovil.com"
                    )
                      ? ".campusmovil.com"
                      : window.location.hostname;
                    Cookies.remove(`sourceList${prefix}`, {
                      domain: domain,
                      path: "/",
                    });

                    history.push("/settings/report_cards");
                  } else {
                    goApp1("ciclo");
                  }
                }}
              >
                <i className="bi bi-chevron-left"></i>

                {t("reportCards:page.back")}
              </div>
            </h6>
          </Col>
          <Col sm={3} md={3} xl={4} xxl={4} style={{ color: "#6c757d" }}>
            {isEditing ? (
              <Formik initialValues={templateInformation}>
                {(values) => (
                  <Row>
                    <Col lg={12}>
                      <FieldInput
                        name="name"
                        type="text"
                        label={false}
                        placeholder=""
                        onBlur={() => {
                          templateInformation.name = values.values.name;
                          setTemplateInformation(templateInformation);
                          setIsEditing(!isEditing);
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </Formik>
            ) : (
              <Row>
                <Col md={12} onClick={() => setIsEditing(!isEditing)}>
                  {templateInformation.name}
                </Col>
              </Row>
            )}
          </Col>
          <Col sm={3} md={3} xl={3} xxl={4} style={{ color: "#6c757d" }}>
            <Button
              variant="primary"
              className="float-end fw-bold"
              onClick={() => setShowSaveChanges(true)}
              disabled={
                templateInformation.name == "" ||
                !listFormatItems.length ||
                !templateInformation.gradeGroupGradeLevelId
              }
            >
              {templateId
                ? t("reportCards:page.saveChanges")
                : t("reportCards:page.save")}
            </Button>
            {showObservations && (
              <div
                className="me-3"
                style={{ float: "right", cursor: "pointer" }}
                onClick={() => setVisiblePage(visiblePage == 1 ? 2 : 1)}
              >
                {visiblePage == 2 && <i className="bi bi-chevron-left"></i>}
                <p className="d-inline fw-bold text-dark ">
                  {visiblePage == 2
                    ? t("reportCards:page.page1")
                    : t("reportCards:page.page2")}
                </p>
                {visiblePage == 1 && <i className="bi bi-chevron-right"></i>}
              </div>
            )}
          </Col>
          <Col sm={3} md={3} xl={2} xxl={2}></Col>
        </Row>
        {/* body */}
        <Row>
          {/* Assignment Details */}
          <Col sm={3} md={3} xl={3} xxl={2} style={customStyles.columnBase}>
            <AssignmentDetails
              schoolCycles={schoolCycles}
              cycleSelected={cycleSelected}
              setCycleSelected={setCycleSelected}
              showObservations={showObservations}
              setShowObservations={setShowObservations}
              setVisiblePage={setVisiblePage}
              availableAllocationValues={availableAllocationValues}
              templateInformation={templateInformation}
              setTemplateInformation={setTemplateInformation}
              classFilter={classFilter}
              getClass={getClass}
              setDefaulsClasses={setDefaulsClasses}
              cleanSelectedClasses={cleanSelectedClasses}
              setClassFilter={setClassFilter}
            />
          </Col>
          {/* Editing area */}
          <Col sm={6} md={6} xl={7} xxl={8} style={customStyles.columnBase}>
            <Row style={customStyles.solidBackground}>
              {/* displays warnings if any */}
              {error.active && (
                <Col md={12}>
                  <Alert key={1} variant={"warning"}>
                    {error.message}
                  </Alert>
                </Col>
              )}
              <Col md={12} id="templateContainer">
                {/* general data */}
                <Row className="mb-4">
                  <Col md={2} style={{ textAlign: "center" }}>
                    <img
                      src={organizationInfo.logoUrl}
                      className="img-fluid"
                      style={{ maxHeight: "100px" }}
                      alt=""
                      id="logo"
                    />
                  </Col>
                  <Col md={10}>
                    <Row>
                      <Col md={5}>
                        <Col md={12}>
                          <h5 className="fw-bold">{organizationInfo.name}</h5>
                        </Col>
                        <Col md={12}>{t("reportCards:page.studentName")}</Col>
                      </Col>
                      <Col md={7} className="fw-bold">
                        <Row>
                          <Col md={4}>{t("reportCards:page.cycle")}</Col>
                          <Col md={4}>{t("reportCards:page.grade")}</Col>
                          <Col md={4}>{t("reportCards:page.group")}</Col>
                        </Row>
                        <Row>
                          <Col md={4}>{cycleSelected.name}</Col>
                          <Col md={4}>{cycleSelected.gradeName || "-"}</Col>
                          <Col md={4}>{cycleSelected.groupName || "-"}</Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* pagina 1 */}
                {visiblePage == 1 && (
                  <div>
                    {/* periods */}
                    <Row className="mb-4">
                      <Col
                        md={{ span: 8, offset: 4 }}
                        xxl={{ span: 9, offset: 3 }}
                      >
                        <Row className="border-bottom">
                          {[1, 2, 3, t("reportCards:page.average")].map(
                            (item, index) => {
                              return (
                                <Col
                                  key={index}
                                  md={3}
                                  className="p-1 text-center"
                                >
                                  {index == 3
                                    ? item
                                    : t("reportCards:page.period") + " " + item}
                                </Col>
                              );
                            }
                          )}
                        </Row>
                      </Col>
                      <Col
                        md={{ span: 8, offset: 4 }}
                        xxl={{ span: 9, offset: 3 }}
                      >
                        <Row>
                          {[1, 2, 3, "N"].map((item) => {
                            return (
                              <Col
                                key={item}
                                md={3}
                                className="p-1 text-center"
                              >
                                {t("reportCards:page.rating")}
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      {/* item drop zone */}
                      <Col md={4} xxl={3} style={{ minHeight: "500px" }}>
                        {reloadBaseList ? null : (
                          <ReactSortable
                            list={listFormatItems}
                            setList={(list) => {
                              updateFormatList(list), subjectCounter(list);
                            }}
                            group={{ name: "options" }}
                            animation={200}
                            delayOnTouchStart={true}
                            removeOnSpill={true}
                            onEnd={(evt) => deleteItem(evt)}
                            handle=".bi-list"
                            style={customStyles.dropZone}
                            sort={lockItemSorting}
                          >
                            {listFormatItems.map((item, index) => (
                              <ReportCardItemCreator
                                key={index}
                                item={item}
                                position={index}
                                getOptions={getClass}
                                defaulsOptions={defaulsClasses}
                                selectClass={selectClass}
                                editAveragingFormat={editAveragingFormat}
                                setshowSettingAveraging={
                                  setshowSettingAveraging
                                }
                                changeClassesSelectionMode={
                                  changeClassesSelectionMode
                                }
                                selectClassesForAveraging={
                                  selectClassesForAveraging
                                }
                                removeItemByButton={removeItemByButton}
                              />
                            ))}
                          </ReactSortable>
                        )}
                      </Col>
                      <Col md={8} xxl={9} style={{ marginTop: "5px" }}>
                        {listFormatItems.map((item, index) => {
                          return (
                            item.type != "phantomElement" &&
                            generateRow(item.type, index)
                          );
                        })}
                      </Col>
                    </Row>
                  </div>
                )}
                {/* comment view */}
                {visiblePage == 2 && (
                  <div>
                    {/* pagina 2 */}
                    <Row>
                      <Col md={{ span: 9, offset: 3 }}>
                        <Row>
                          <Col md={3}>{t("reportCards:page.period")} 1</Col>
                          <Col md={3}>{t("reportCards:page.period")} 2</Col>
                          <Col md={3}>{t("reportCards:page.period")} 3</Col>
                          <Col md={3}>{t("reportCards:page.period")} 4</Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={3} style={{ marginTop: "29px" }}>
                        {reloadBaseList ? null : (
                          <ReactSortable
                            list={listFormatItems}
                            setList={(list) => {
                              updateFormatList(list), subjectCounter(list);
                            }}
                            group={{ name: "options" }}
                            animation={200}
                            delayOnTouchStart={true}
                            removeOnSpill={true}
                            onEnd={(evt) => deleteItem(evt)}
                            handle=".bi-list"
                            style={customStyles.dropZone}
                            sort={lockItemSorting}
                          >
                            {listFormatItems.map((item, index) => (
                              <ReportCardItemCreator
                                key={index}
                                item={item}
                                position={index}
                                getOptions={getClass}
                                defaulsOptions={defaulsClasses}
                                selectClass={selectClass}
                                editAveragingFormat={editAveragingFormat}
                                setshowSettingAveraging={
                                  setshowSettingAveraging
                                }
                                changeClassesSelectionMode={
                                  changeClassesSelectionMode
                                }
                                selectClassesForAveraging={
                                  selectClassesForAveraging
                                }
                                removeItemByButton={removeItemByButton}
                                commentView={true}
                              />
                            ))}
                          </ReactSortable>
                        )}
                      </Col>
                      <Col md={9}>
                        <Row>
                          {[1, 2, 3].map((v) => {
                            return (
                              <Col
                                key={v}
                                md={3}
                                style={{
                                  borderRight: "solid 1px black",
                                  marginTop: "29px",
                                }}
                              >
                                {new Array(numberOfSubjects)
                                  .fill(1)
                                  .map((v) => {
                                    return (
                                      <Row
                                        key={v}
                                        style={{
                                          height: "65px",
                                          borderBottom: "solid 1px black",
                                        }}
                                      >
                                        <p>
                                          {t(
                                            "reportCards:page.teachersComments"
                                          )}
                                        </p>
                                      </Row>
                                    );
                                  })}
                              </Col>
                            );
                          })}
                          <Col
                            key={0}
                            md={3}
                            style={{
                              marginTop: "29px",
                            }}
                          >
                            {new Array(numberOfSubjects).fill(1).map((v) => {
                              return (
                                <Row
                                  key={v}
                                  style={{
                                    height: "65px",
                                    borderBottom: "solid 1px black",
                                  }}
                                >
                                  <p>
                                    {t("reportCards:page.teachersComments")}
                                  </p>
                                </Row>
                              );
                            })}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          {/* selectable elements  */}
          <Col sm={3} md={3} xl={2} xxl={2} style={customStyles.columnBase}>
            <DraggableElements uniqueItems={uniqueItems} />
          </Col>
        </Row>
      </Container>

      {/* modales */}
      <NewTamplateModal
        showNewTamplateModal={showNewTamplateModal}
        setShowNewTamplateModal={setShowNewTamplateModal}
        setNewTemplate={setTemplateInformation}
        setListFormatItems={setListFormatItems}
      />
      <SaveChangesModal
        showSaveChanges={showSaveChanges}
        setShowSaveChanges={setShowSaveChanges}
        saveTemplate={saveTemplate}
        templateName={templateInformation.name}
        saving={saving}
      />
      <SettingAveragingFormatModal
        showSettingAveraging={showSettingAveraging}
        setshowSettingAveraging={setshowSettingAveraging}
        editingGradingFormat={editingGradingFormat}
        saveAverageformat={saveAverageformat}
        changeClassesSelectionMode={changeClassesSelectionMode}
      />
      <ConfirmDeleteModal
        showConfirmDeleteModal={showConfirmDeleteModal}
        setShowConfirmDeleteModal={setShowConfirmDeleteModal}
        templateToDelete={templateToDelete}
        templateInformation={templateInformation}
        setTemplateInformation={setTemplateInformation}
        setListFormatItems={setListFormatItems}
        getTemplates={getTemplates}
      />
    </div>
  );
};

export default ReportCardsView;

ReportCardsView.propTypes = {
  match: PropTypes.obj,
};
